import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["event", "method", "action"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)), ["prevent"])),
    ref: $props.name,
    event: $props.event,
    autocomplete: "off",
    method: $props.method,
    action: $props.action,
    class: "needs-validation",
    novalidate: ""
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 40, _hoisted_1))
}