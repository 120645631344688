<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 class="mb-5">Výber typu zasklenia</h2>
        <div class="list-group">
          <div v-for="t in this.store.types" v-bind:key="t.id" class="">
            <router-link v-if="t.name !== ''" :to="t.id != 9 ? `/types/${t.id}` : `/fixies`"
                         class="font-weight-bold text-uppercase list-group-item list-group-item-action">
              <!--<img src="" width="30" height="30" class="d-inline-block align-top" alt=""> -->
              {{ t.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import Security from './security'
import {store} from './store'
import {Models,FixType, WallType} from "@/components/models";

export default {
  name: 'TypeList',
  data() {
    return {
      store,
    }
  },
  beforeMount() {
    Security.requireToken();
    // všetko sa nastaví na defaultné hodnoty
    this.store.order = {...this.store.order_default}

    let wallsEmpty: WallType[] = [];
    this.store.order.walls = wallsEmpty;
    let fixiesEmpty: FixType[] = [];
    this.store.order.fixes = fixiesEmpty;
    this.store.profile = {...this.store.profile_default}
    this.store.fix = {...this.store.fix_default}

    const orderHistoryEmpty: Models.OrderHistory[] = [];
    this.store.orderHistory = orderHistoryEmpty;
    
    // do premennej strore.types sa uložia typy zasklení
    fetch(store.Api_url + "/v1/type?query=active:1")
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            console.log("error:", responseData.message)
            this.$emit('error', responseData.message);
          } else {
            let types: Models.LayoutType[] = []
            store.types = types

            for (let key = 0;key < responseData.length ; key++) {
              store.type = {id: responseData[key].id,
                name: responseData[key].name,
                code: responseData[key].code,
                thumbnail:responseData[key].thumbnail}

              store.types.push(store.type as Models.LayoutType);

            }
          }
    });

    fetch(store.Api_url + "/v1/email-recipient",Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            console.log("error:", responseData.message)
            //this.$emit('error', responseData.message);
          } else {
            store.emailRecipients = responseData;
            store.emailRecipientOptions = [];
            store.emailRecipients.forEach((recip: Models.EmailRecipient) => {
              let recipTemp = {
                id: recip.id,
                name: recip.name + " * " + recip.email + " * " + recip.glassType
              }
              store.emailRecipientOptions.push(recipTemp)
            })


          }
    });      
  },
}
</script>