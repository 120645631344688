import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f07b6b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-round-lg border-1 border-green-500" }
const _hoisted_2 = { class: "surface-border border-round-top-lg justify-content-center bg-green-300" }
const _hoisted_3 = { class: "text-center uppercase" }
const _hoisted_4 = { class: "flex flex-row flex-wrap align-items-center justify-content-center" }
const _hoisted_5 = { class: "w-7 p-1" }
const _hoisted_6 = { class: "w-5 p-1" }
const _hoisted_7 = { class: "ml-2 p-2" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroupAddon = _resolveComponent("InputGroupAddon")!
  const _component_InputGroup = _resolveComponent("InputGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, "Profil: " + _toDisplayString(this.$props.profile.name), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputGroup, { class: "mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroupAddon, { class: "w-6 font-normal text-base text-700 justify-content-start" }, {
              default: _withCtx(() => [
                _createTextVNode("Šírka")
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-4 font-normal text-base text-700" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.profile.width), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
              default: _withCtx(() => [
                _createTextVNode("[mm]")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_InputGroup, { class: "mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroupAddon, { class: "w-6 font-normal text-base text-700 justify-content-start" }, {
              default: _withCtx(() => [
                _createTextVNode("Redukcia skla")
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-4 font-normal text-base text-700" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.profile.glassReduction), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
              default: _withCtx(() => [
                _createTextVNode("[mm]")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_InputGroup, { class: "mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroupAddon, { class: "w-6 font-normal text-base text-700 justify-content-start" }, {
              default: _withCtx(() => [
                _createTextVNode("Cut redukcia")
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-4 font-normal text-base text-700" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.profile.cutReduction), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
              default: _withCtx(() => [
                _createTextVNode("[mm]")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            src: this.$props.profile.thumbnail_dim,
            width: "200",
            height: "200",
            alt: "Obrázok"
          }, null, 8, _hoisted_8)
        ])
      ])
    ])
  ]))
}