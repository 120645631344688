<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="mt-5">Prihlásenie sa</h1>
        <hr>
        <form-tag @myevent="submitHandler" name="myform" event="myevent">
          <text-input
              v-model="email"
            label="Email"
            type="email"
            name="email"
            id="loginEmail"
            required="true">
          </text-input>

          <text-input
              v-model="password"
              label="Password"
              type="password"
              name="password"
              id="loginPassword"
              required="true">
          </text-input>
        <hr>
        <PrimevueButton type="submit" label="Prihlásiť sa" text raised class=""/>
        </form-tag>
      </div>

    </div>
  </div>
</template>
<script lang="ts">
import TextInput from "@/components/forms/TextInput.vue";
import FormTag from "@/components/forms/FormTag.vue";
import { store } from './store';
import Security from './security';
import router from "@/router";
import notie from 'notie/dist/notie';
import PrimevueButton from 'primevue/button';

export default{
  name : 'LoginComponent',
  components:{
    FormTag,
    TextInput,
    PrimevueButton,
  },
  data(){
    return{
      email:"",
      password:"",
      store,
    }
  },
  methods:{
    submitHandler(){
      console.log('submitHandler called - OK');

      // s tadialto posielame data na backend
      // nastavime data ako payload
      const payload = {
        email: this.email,
        password : this.password
      }

      // odosleme login data

      fetch(store.Api_url+'/login', Security.requestOptions("POST",payload))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error){
              console.log("Error:", responseData.message)
              notie.alert({
                type: 'error',
                text: responseData.message
              })
            }else{
              // console.log(responseData)
              if (typeof responseData.data.token != "undefined"){
                // premenna store, nacitane cez store.js, je globalna kniznica pre ukladanie globalnych premennych.
                // Tieto globalne premenne je mozne menit z akehokolvek componentu

                //console.log("token:", responseData.data.token)
                store.token = responseData.data.token.UserToken
                store.user = {
                  id: responseData.data.user.id,
                  firstName:responseData.data.user.firstName,
                  lastName:responseData.data.user.lastName,
                  company:responseData.data.user.company,
                  email:responseData.data.user.email,
                  admin:responseData.data.user.admin,
                }
                store.admin = responseData.data.admin

                notie.alert({
                  type: 'success',
                  text: "Úspešné prihlásenie",
                  position: "bottom"
                })

                // ulozime token do cookie
                let date = new Date()
                let expDays = 1;

                date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000 ));
                const expires = "expires="+date.toUTCString();

                document.cookie = "_bks_data="
                    + JSON.stringify(responseData.data)
                    + ";"
                    + expires
                    + "; path=/; SameSite=strict; Secure;"

                router.push('/')
              }
            }
          })
    }
  }
}
</script>

<style scoped>
  button {
    border-radius: 6px;
  }
</style>