import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "name", "id", "placeholder", "required:string", "min", "max", "value", "autocomplete"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: $props.id,
      class: "form-label"
    }, _toDisplayString($props.label), 9, _hoisted_2),
    _createElementVNode("input", {
      type: $props.type,
      name: $props.name,
      id: $props.id,
      placeholder: $props.placeholder,
      "required:string": $props.required,
      min: $props.min,
      max: $props.max,
      value: $props.modelValue,
      autocomplete: $props.name + '-new',
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue',($event.target as any).value))),
      class: "form-control"
    }, null, 40, _hoisted_3)
  ]))
}