<template>
  <div class="surface-50">
    
      <div class="p-2 surface-border border-bottom-2 flex flex-column md:flex-row md:justify-content-between">
        <div class="pb-2 pt-2">Odberateľ: {{ this.store.order.user.company }} (<em>{{ this.store.order.user.firstName }}
          {{ this.store.order.user.lastName }}, {{ this.store.order.user.phone }}</em>)
        </div>
        <div>Aktuálny stav:
          <Tag class="p-2 ml-1 font-medium text-base" :value="this.store.order.currentState.label"
               :severity="Utils.getStateSeverity(this.store.order.currentState.reference)"/>
        </div>
        <div class="p-2 ml-5" v-if="this.store.order.id > 0">
          <em>Objednávka naposledy uložená
            <i class="pi pi-clock"></i>
            {{ Utils.parseToSlovakDateTime(this.store.order.modifiedDate) }} - {{ this.store.order.modifiedUser.firstName }}
            {{ this.store.order.modifiedUser.lastName }}
          </em>
        </div>
      </div>

      <div class="p-2 surface-border border-bottom-2 flex flex-row flex-wrap align-items-center" v-if="this.store.order.id > 0">
            <div class="pb-2 pt-2 font-medium text-xl uppercase">#{{this.store.order.orderNumber}} - {{ this.store.type.name }}
            </div>
      </div>

      <div class="flex flex-row flex-wrap h-full">
        <div class="w-6 m-2 p-2 surface-card shadow-2 border-round-lg h-full align-items-center justify-content-center">
          <InputGroup class="h-2rem">
            <InputGroupAddon class="wfloorlab h-full font-normal text-base text-700 justify-content-start">Meno a priezvisko
            </InputGroupAddon>
            <InputText
                class="justify-content-start wfloorinp"
                inputStyle="background: #b8d6f0; text-align: left"
                name="nameSurname"
                id="nameSurname"
                placeholder="Zadaj meno a priezvisko"
                required:string="required"
                v-model="this.store.order.customerName"
                autocomplete="nameSurname-new"
            >
            </InputText>
            <InputGroupAddon class="wterminlab h-full font-normal text-base text-700 justify-content-start">Mobilný kontakt
            </InputGroupAddon>
            <InputText class="wtermincal justify-content-start" inputStyle="background: #b8d6f0; text-align: left"
                      name="phone"
                      id="phone"
                      placeholder="Zadaj telefón"
                      required:string="required"
                      v-model="this.store.order.phone"
                      autocomplete="phone-new"
            >
            </InputText>

          </InputGroup>
          <InputGroup class="h-2rem mt-2">
            <InputGroupAddon class="wfloorlab h-full font-normal text-base text-700 justify-content-start">Adresa
            </InputGroupAddon>
            <InputText
                class="justify-content-start wfloorinp"
                inputStyle="background: #b8d6f0; text-align: left"
                name="address"
                id="address"
                placeholder="Zadaj adresu"
                required:string="required"
                v-model="this.store.order.address"
                autocomplete="address-new"
            >
            </InputText>
            <InputGroupAddon class="wterminlab h-full font-normal text-base text-700 justify-content-start">E-mail
            </InputGroupAddon>
            <InputText type="email" class="wtermincal justify-content-start" inputStyle="background: #b8d6f0; text-align: left"
                      name="email"
                      id="email"
                      placeholder="Zadaj e-mail"
                      required:string="required"
                      v-model="this.store.order.email"
                      autocomplete="email-new"
            >
            </InputText>

          </InputGroup>
          <InputGroup class="h-2rem mt-2">
            <InputGroupAddon class="wfloorlab-2 h-full font-normal text-base text-700 justify-content-start">Poschodie
            </InputGroupAddon>
            <InputNumber
                inputId="poschodie"
                class="justify-content-start wfloorinp-2"
                inputStyle="background: #b8d6f0; text-align: left"
                name="floor"
                id="floor"
                placeholder="Zadaj poschodie"
                required:string="required"
                v-model="this.store.order.floor"
                autocomplete="floor-new"
            >
            </InputNumber>
            <InputGroupAddon class="wterminlab h-full font-normal text-base text-700 justify-content-start">Termín montáže
            </InputGroupAddon>
            <Calendar class="wtermincal-2"
                      v-model="termin"
                      showIcon
                      dateFormat="dd.mm.yy"
            />
          </InputGroup>
        </div>
      </div>
      
      <div v-for="(fix,index) in this.store.order.fixes" :key="index" class="align-items-center justify-content-center w-12 surface-card shadow-2 border-round-lg p-2 m-2">
        <CardFix :fixKey="index" :validationOK="true" @change-fix="saveFixes" @delete-fix="deleteFix">
        </CardFix>
      </div>

      <div class="flex flex-row flex-wrap">
        <Card class="shadow-2 m-2 w-3">
          <template #title>Prehľad stavov</template>
          <template #content>
            <ul>
              <li v-for="history in this.store.orderHistory" v-bind:key="history.id">
                <i class="pi pi-clock"></i>
                {{ Utils.formatToSlovak(history.createDate) }}
                <Tag :value="history.state.label" :severity="Utils.getStateSeverity(history.state.reference)"/>
                - {{ history.user.firstName }} {{ history.user.lastName }}
              </li>
            </ul>
          </template>
        </Card>

        <Card v-if="this.store.emailHistories.length > 0" class="shadow-2 m-2 w-6">
          <template #title>Prehľad histórie zaslaných emailov</template>
          <template #content>
            <ul>
              <li v-for="history in this.store.emailHistories" v-bind:key="history.id">
                <i class="pi pi-clock"></i>
                {{ Utils.formatToSlovak(history.createDate) }}
              <!--  <Tag :value="history.state.label" :severity="Utils.getStateSeverity(history.state.reference)"/> -->
                Od: <span class="mr-1 font-medium">{{ history.user.firstName }} {{ history.user.lastName }}   </span> 
                Komu: <span class="mr-1 font-medium">{{ history.whom }}   </span>  Predmet: <span class="font-medium">{{ history.subject }}</span>
              </li>
            </ul>
          </template>
        </Card>
    </div>

  </div>
  <!--  floating button -->
  <div class="bottom-action-row">
    <a class="mr-5"
       @click="saveOrder()">
      <PrimevueButton label="ULOŽIŤ a PREPOČÍTAŤ"  raised class="uppercase" severity="info" icon="pi pi-save" />
    </a>

    <a class=" mr-5 ml-5">
      <PrimevueButton class="mr-5" label="Pridať fix" severity="success" icon="pi pi-plus"  raised @click="addFix()"/>
    </a>

    <a v-if="this.store.order.id > 0" class="mr-2 ml-2" @click="!this.store.hasBeenSent ? Utils.changeState(Models.State.SENT) : null">
      <PrimevueButton label="Odoslať"  raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.SENT)"
                      :disabled="this.store.hasBeenSent"
      />
    </a>

    <a v-if="this.store.user.admin" class="mr-2 ml-2"
       @click="!this.store.hasBeenInProduction && this.store.hasBeenSent ? Utils.changeState(Models.State.PRODUCTION): null">
      <PrimevueButton label="Nastaviť výrobu"  raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.PRODUCTION)"
                      :disabled="this.store.hasBeenInProduction || !this.store.hasBeenSent"
      />
    </a>

    <a v-if="this.store.user.admin" class="mr-2 ml-2"
       @click="!this.store.hasBeenReady && this.store.hasBeenInProduction ? Utils.changeState(Models.State.READY): null">
      <PrimevueButton label="Pripravená na prevzatie"  raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.READY)"
                      :disabled="this.store.hasBeenReady ||  !this.store.hasBeenInProduction || !this.store.hasBeenSent"
      />
    </a>
    <a v-if="this.store.user.admin" class="mr-2 ml-2"
       @click="!this.store.hasBeenFinished && this.store.hasBeenReady ? Utils.changeState(Models.State.FINISH): null">
      <PrimevueButton label="Ukončená"  raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.FINISH)"
                      :disabled="!this.store.hasBeenReady || this.store.hasBeenFinished"
      />
    </a>
    <a v-if="this.store.user.admin && this.store.emailLink !== ''" class="mr-2 ml-2"
       :href="this.pdfGlassUrl" target="_blank">
      <PrimevueButton label="PDF sklá"  raised class="uppercase" severity="secondary" icon="pi pi-eye"/>
    </a>
    <a v-if="this.store.emailLink !== ''" @click="this.store.isSendEmail = !this.store.isSendEmail">
      <PrimevueButton label="Email" :severity="this.store.hasBeenEmailSent ? 'warning' : 'success'" raised class="uppercase" icon="pi pi-envelope" />
              <em v-if="this.store.hasBeenEmailSent" class="ml-1 ext-muted fs-6 text-lg text-yellow-500"><small>email bol už zaslaný</small></em>
    </a> 
    <EmailList v-if="this.store.isSendEmail && this.store.emailLink !== ''" v-model="this.recipId" 
        :options="this.store.emailRecipientOptions" 
        optionLabel="name" optionValue="id" 
        class="md:w-30rem absolute email-list" 
        @change="sendEmail()"
    />
  </div>
</template>     

<script lang="ts">

import Security from '../security'
import notie from 'notie/dist/notie'
import {store} from '../store'
import PrimevueButton from 'primevue/button';
import {FixType, Models, OptionType, ProfileType} from '../models';
import Calendar from 'primevue/calendar';
import CardFix from "@/components/forms/CardFix.vue";
import router from "@/router";
import Utils from '../utils';
import Tag from "primevue/tag";
import Card from "primevue/card";
import EmailList from "primevue/listbox";

export default {
  name: 'FormFixInput',
  components: {
    PrimevueButton,
    Calendar,
    CardFix,
    Tag,
    Card, 
    EmailList,
  },
  computed: {
    Models() {
      return Models
    },
    Utils() {
      return Utils
    },    
  },
  data() {
    return {
      store,
      termin: null,
      pdfGlassUrl: String,
      recipId : Number,

    }
  },
  beforeMount() {
    Security.requireToken();
    this.store.emailLink = "";
    this.pdfGlassUrl = "";
    this.recipId = 1;
    this.store.isSendEmail = false;

    if (this.store.order.id === 0) {
      this.store.type = this.store.types.find(type => type.code === 'FIX');
      this.store.order.type = this.store.type;
      this.store.order.user = this.store.user;
      //console.log("JE order 0:", store.currentOrderId)
    } else {
      this.pdfGlassUrl = this.store.Api_url + "/pdf/fg/" + this.store.order.id + "?format=screen"
      this.store.type = this.store.order.type
      this.store.emailLink = this.store.Api_url + '/email/' + this.store.order.id
      // ak objednávka nemá fixy, tak vytvor prázdne pole
      if(!this.store.order.fixes){
         let fixes: FixType[] = [];
         this.store.order.fixes = fixes;
      }else{
        let fixes: FixType[] = [];
        fixes = this.store.order.fixes.filter( obj => obj.active);
        this.store.order.fixes = fixes;      

      }

    }

    Utils.updateOrderHistory(this.store.order.id);  
    Utils.getOrderStates();
    Utils.getEmailHistory(this.store.order.id);
    this.termin = Utils.parseToSlovakDate(this.store.order.installationDate);

    fetch(this.store.Api_url + "/v1/profile",Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            
            this.$emit('errorProfile', responseData.message);
          } else {

            this.store.profiles = [];

            this.store.binderProfiles = [];

            this.store.ramed_profile_type = [];

            this.store.binder_profile_type = [];

            for (let key = 0; key < responseData.length; key++) {
              const profile = responseData[key] as ProfileType
              if (profile.active) {
                // make sure profile Id is still integer, not string
                profile.id = Number(profile.id)

                if (!profile.binder) {
                  this.store.profiles.push(profile);
                  let ramedProfileType = {
                    code: String(profile.id),
                    name: String(profile.name),
                  }
                  this.store.ramed_profile_type.push(ramedProfileType as OptionType);
                } else {
                  this.store.binderProfiles.push(profile);
                  let binderProfileType = {
                    code: String(profile.id),
                    name: String(profile.name),
                  }
                  this.store.binder_profile_type.push(binderProfileType as OptionType);
                }

              }
            }
            // this.updateProfiles();
          }
     });
    fetch(this.store.Api_url + "/v1/email-recipient",Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            console.log("error:", responseData.message)
            //this.$emit('error', responseData.message);
          } else {
            this.store.emailRecipients = responseData;
            this.store.emailRecipientOptions = [];
            this.store.emailRecipients.forEach((recip: Models.EmailRecipient) => {
              let recipTemp = {
                id: recip.id,
                name: recip.name + " * " + recip.email + " * " + recip.glassType
              }
              this.store.emailRecipientOptions.push(recipTemp)
            })


          }
        });
  },
  mounted() {

    //Utils.updateOrderHistory();
   // console.log("idOrder mounted:", this.store.order.id)
  },
  methods: {
    sendEmail(){
      this.store.isSendEmail = false;
      if(this.recipId == null){
        this.recipId = 1
      }

      this.store.emailRecipient = this.store.emailRecipients.filter(obj => obj.id == this.recipId)[0];
      Utils.sendingEmail("fixes",this.recipId)
      this.recipId = 1
    },
    
    addFix(): void {
      let fix: FixType = {...this.store.fix_default};
      
      if (this.store.order.id !== 0) {
         // pridá sa fix do tabuľky fix 
          fix.order.id = this.store.order.id;
          fetch(this.store.Api_url + "/v1/fix", Security.requestOptions("POST", fix))
            .then((response) => response.json())
            .then((responseData) => {
              if (responseData.error) {
                 this.$emit('error', responseData.message);
                 console.log("error: ",responseData.message);
              } else {
                console.log("New Fix: ",responseData);
                //this.getAllFixes(this.store.order.id)
                this.store.order.fixes.push(responseData.data)
                this.saveOrder();
              }
          });          
      }else{
        this.store.order.fixes.push(fix)
        this.saveOrder();
      }
      //this.saveOrder();
    },
    deleteFix(index:number): void {

         // vymaže sa sa fix z tabuľky fix 
         this.store.order.fixes[index].active = false;
         this.store.order.fixes[index].deletedDate = new Date();
    
         //this.store.order.fixes.splice(index, 1);
         this.saveOrder();
         router.push('/fixies');
    },
    updateProfiles() {
      //console.log("Update profil: ");
      this.store.order.fixes.forEach((fix: any, key: number) => {
        //console.log("fix: ",fix);
        let profile = this.store.profiles.filter(obj => obj.id == fix.profile.id)[0];
        console.log("Fix Profile: ", profile);
        if (profile) {
          this.store.order.fixes[key].profile = profile;
        }
        let binderProfile = this.store.binderProfiles.filter(obj => obj.id == fix.binderProfile.id)[0];
        console.log("Fix Binder Profile: ", binderProfile);
        if (binderProfile) {
          this.store.order.fixes[key].binderProfile = binderProfile;
        }
      });

    },
    saveFixes(fixData){
      console.log("Fix data: ",fixData);
      if(fixData){
        switch(fixData.fixName){
          case "fixName": this.store.order.fixes[fixData.fixKey].name = fixData.fixValue;
          break;
          case "width": this.store.order.fixes[fixData.fixKey].width = fixData.fixValue;
          break;
          case "heightA": this.store.order.fixes[fixData.fixKey].heightA = fixData.fixValue;
          break;
          case "heightB":this.store.order.fixes[fixData.fixKey].heightB = fixData.fixValue;
          break;
          case "glassType":this.store.order.fixes[fixData.fixKey].glassType = fixData.fixValue;
          break;
          case "binderCount":this.store.order.fixes[fixData.fixKey].binderCount = fixData.fixValue;
          break;
          case "profile":
            this.store.order.fixes[fixData.fixKey].profile.id = fixData.fixValue;
          break;
        }
      }
  
    },
  
    saveOrder(): void {
      this.store.order.installationDate = Utils.saveDate(this.termin);
      
      if (this.store.order.id === 0) {
         //console.log("Store.Order:", this.store.order); 
         this.store.order.modifiedUser = this.store.user;
     
          fetch(this.store.Api_url + "/v1/order", Security.requestOptions("POST", this.store.order))
            .then((response) => response.json())
            .then((responseData) => {
              if (responseData.error) {

                //this.$emit('error', responseData.message);
                notie.alert({
                  type: 'danger',
                  text: "Problém s uložením",
                  position: "bottom"
                })
              } else {
                //console.log("Order",responseData);

                this.store.order = responseData.data;
                if(!responseData.data.fixes){
                
                   let fixes: FixType[] = [];
                   this.store.order.fixes = fixes;
                }
                Utils.updateOrderHistory(this.store.order.id);
                notie.alert({
                  type: 'success',
                  text: "Úspešne uložené",
                  position: "bottom",
                  time:1
              });
              }
          });

        } else {
          
          this.editOrder(String(this.store.order.id));
        }

       
    },
    editOrder(orderId: String) {
      this.store.order.modifiedUser = this.store.user;
      fetch(this.store.Api_url + "/v1/order/" + orderId, Security.requestOptions("PUT", this.store.order))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error) {
              //console.log("error:", responseData.message);
              //this.$emit('error', responseData.message);
              notie.alert({
                  type: 'danger',
                  text: "Problém s uložením",
                  position: "bottom"
                })
            } else {
              //console.log("PUT order:", responseData.data);
              this.store.order = responseData.data;
              let modifiedDateTemp = new Date(this.store.order.modifiedDate)
              if(Utils.isDSTInEffect(modifiedDateTemp)){
                modifiedDateTemp = Utils.minusOneHour(modifiedDateTemp)
              }else{
                modifiedDateTemp = Utils.minusOneHour(modifiedDateTemp)
                modifiedDateTemp = Utils.minusOneHour(modifiedDateTemp)
              }
              this.store.order.modifiedDate = modifiedDateTemp;
              //this.termin = this.parseToSlovakDate(responseData.data.installationDate);
              if(!responseData.data.fixes){
                
                   let fixes: FixType[] = [];
                   this.store.order.fixes = fixes;
                }else{
                  // vyselektuje len aktívne fixy
                  let tempFixes: FixType[] = [];
                  responseData.data.fixes.forEach((fix: FixType) => {
                    if(fix.active){
                      tempFixes.push(fix);
                      console.log(fix);
                    }
                  });
                  this.store.order.fixes = tempFixes;        

              }
              // this.updateProfiles();
              notie.alert({
                  type: 'success',
                  text: "Úspešne uložené",
                  position: "bottom",
                  time:1
              });
            }
          });
    },

  },

  beforeUnmount() {

  }
}
</script>

<style scoped>
  button {
    border-radius: 6px;
  }
  .p-button {
    padding: 0.4rem 0.7rem;
  }
  .p-inputtext{
    background: #b8d6f0; 
    text-align: left;
    height: 2.1rem; 
  } 
  .p-inputgroup-addon {
    padding: 1rem 0.4rem;
  }
  .p-calendar {
    height: 2.1rem;
    padding: 0.1rem 0.2rem;
    background: #b8d6f0;
  }
  .wfloorlab{
    width: 40.1% !important;
  }
  .wfloorinp{
    width: 42.5% !important;
  }

  .wfloorlab-2 {
    width: 40.15% !important;
  }
  
  .wfloorinp-2 {
    width: 43% !important;
  }
  .wterminlab{
    width: 40% !important;
  }
  .wtermincal {
    width: 34% !important;
  }
  .wtermincal-2 {
    width: 34.2% !important;
  }
  .w-5-customer{
    width: 42.5% !important;
  }

  .bottom-action-row{
    position: fixed;
    bottom: 0;
    width:100%;
    height:120px;
    padding: 3rem 0 3rem 1rem;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.7973390039609594) 0%, rgba(0,0,0,0) 100%);
  }

  .p-inputnumber-input {
    height: 2.1rem;

}
.p-inputnumber {
  height: 2.1rem;

}

.email-list {
  top: -167px;
  left: 85rem;
}
</style>