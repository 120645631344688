<template>
  <div class="flex flex-row flex-wrap">
    <div class="w-4 mr-5">
      <div>
        <row-input name="fixName"
                   id="fixName"
                   labelFront="Názov fixu"
                   labelBack="[-]"
                   :defaultValue="this.store.order.fixes[this.$props.fixKey].name"
                   :validationAlert="!this.validationOK"
                   type="text"
                   ref="fixName"
                   @new-wall-value="changeInput"
        >
        </row-input>
        <div class="mb-2 mt-2">
          <img src="/img/fix-atyp.jpg" width="250" alt="Atypický fix" v-if="this.store.order.fixes[this.$props.fixKey].atypical" />
          <img src="/img/fix-rectangle.jpg" width="250" alt="Atypický fix" v-else />
        </div>
        <row-input name="width"
                   id="widthFix"
                   required="true"
                   labelFront="Šírka fixu"
                   labelBack="[mm]"
                   :defaultValue="this.store.order.fixes[this.$props.fixKey].width"
                   type="number"
                   ref="width"
                   @new-wall-value="changeInput"
        >
        </row-input>

        <InputSwitch
                  id="atypical"
                     v-model="this.store.order.fixes[this.$props.fixKey].atypical"
                     class="mt-2 offset-lock offset-lock-left"
                     @input="changeInput"
        ></InputSwitch>
        <label for="atypical" class="ml-2">Atypický (rôzne výšky)</label>

        <row-input v-if="this.store.order.fixes[this.$props.fixKey].atypical"
                  name="heightA"
                   id="heightAfix"
                   required="true"
                   labelFront="Výška A"
                   labelBack="[mm]"
                   :defaultValue="this.store.order.fixes[this.$props.fixKey].heightA"
                   type="number"
                   ref="heightA"
                   :validationAlert="!this.validationOK"
                   @new-wall-value="changeInput">
        </row-input>
        <row-input v-else
                  name="heightA"
                   id="heightAfix"
                   required="true"
                   labelFront="Výška"
                   labelBack="[mm]"
                   :defaultValue="this.store.order.fixes[this.$props.fixKey].heightA"
                   type="number"
                   ref="heightA"
                   :validationAlert="!this.validationOK"
                   @new-wall-value="changeInput">
        </row-input>
        <row-input name="heightB"
                   v-if="this.store.order.fixes[this.$props.fixKey].atypical"
                   id="heightBfix"
                   required="true"
                   labelFront="Výška B"
                   labelBack="[mm]"
                   :defaultValue="this.store.order.fixes[this.$props.fixKey].heightB"
                   type="number"
                   ref="heightB"
                   :validationAlert="!this.validationOK"
                   @new-wall-value="changeInput">
        </row-input>

        <row-input name="glassType"
                   id="glassType"
                   labelFront="Typ skla"
                   labelBack="[-]"
                   :defaultValue="this.store.order.fixes[this.$props.fixKey].glassType"
                   :validationAlert="!this.validationOK"
                   type="text"
                   ref="glassType"
                   @new-wall-value="changeInput"
        >
        </row-input>
        <InputGroup class="w-full mt-1">
          <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start">Rámový profil
          </InputGroupAddon>
          <Dropdown
              @change="changeProfile"
              v-model="this.store.order.fixes[this.$props.fixKey].profile.id"
              :options="this.store.ramed_profile_type"
              optionLabel="name"
              optionValue="code"
              :placeholder="this.store.order.fixes[this.$props.fixKey].profile.name"
              class="w-2" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem"
          />
          <PrimevueButton  @click="visibleProfiles(false)" class="help-button w-2" label="Nápoveda" severity="warning"/>
         
        </InputGroup>
        <InputGroup class="w-full mt-1">
          <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start">Počet priedelov
          </InputGroupAddon>
          <Dropdown
              @change="changeSelect"
              v-model="this.store.order.fixes[this.$props.fixKey].binderCount"
              :options="this.store.binder_options"
              optionLabel="name"
              optionValue="code"
              :placeholder="this.store.order.fixes[this.$props.fixKey].binderCount"
              class="w-2" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem"
          />
          <InputGroupAddon class="w-2 font-normal text-sm">[ks]</InputGroupAddon>
        </InputGroup>
        <div class="formgroup-inline pl-2 w-full mb-1">
          <div class="text-xs font-light text-500 w-11rem">Počet priedelov nie je počet skiel
          </div>
        </div>

        <InputGroup v-if="this.store.order.fixes[this.$props.fixKey].binderCount > 0" class="w-full mt-1">
          <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start">Priečnikový profil
          </InputGroupAddon>
          <Dropdown
              @change="changeBinderProfile"
              v-model="this.store.order.fixes[this.$props.fixKey].binderProfile.id"
              :options="this.store.binder_profile_type"
              optionLabel="name"
              optionValue="code"
              :placeholder="this.store.order.fixes[this.$props.fixKey].binderProfile.name"
              class="w-2" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem"
          />
          <PrimevueButton  @click="visibleProfiles(true)" class="help-button w-2" label="Nápoveda" severity="warning"/>
        </InputGroup>
        <InputGroup class="w-full mt-1">
          <InputGroupAddon class="w-2 h-full font-normal text-base text-700 justify-content-start">Pozn.
          </InputGroupAddon>
          <InputText class="w-full justify-content-start"
                     name="fixComment"
                     id="fixComment"
                     placeholder="Zadaj poznámku k fixu"
                     v-model="this.store.order.fixes[this.$props.fixKey].desc"
                     autocomplete="fixComment-new"

          >
            <!--  @keyup.enter="changeInput({name:'',value:0})" @blur="changeInput({name:'',value:0})" 
            
            -->
          </InputText>
        </InputGroup>
      </div>

    </div>
    <div class="w-6 mr-2">
      <InputGroup class="">
        <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-300">Výstupné údaje
        </InputGroupAddon>
      </InputGroup>
      <div  class="mt-2 flex flex-row flex-wrap">
        <div class="w-6" v-if="this.store.order.fixes[this.$props.fixKey].binderCount > 0">
          <InputGroup class="mt-1">
            <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-100">Zoznam priečnikov - profil
              {{ this.store.order.fixes[this.$props.fixKey].binderProfile.name }}
              ({{ this.store.order.fixes[this.$props.fixKey].binderProfile.width }}mm)
            </InputGroupAddon>
          </InputGroup>
          <div v-for="(fixB,index) in this.store.order.fixes[this.$props.fixKey].fixBinders" :key="index"
               class="align-items-center justify-content-center w-full surface-card shadow-2 border-round-lg mt-1">
            <CardFixBinder :fixBinderKey="index" :fixKey="this.$props.fixKey"
                           @change-fix-binder="changeFixBinder">
            </CardFixBinder>
          </div>

          <a class="">
            <PrimevueButton class="mt-5   bg-pink-300" label="Prepočítať rozostupy" severity="info" icon="pi pi-refresh"
                            raised @click="calculateBinderOffsets()"/>
          </a>
          <div>
            <em class="text-muted fs-6"><small>
              Pre celkové uloženie kliknite ešte na <br>ULOŽIŤ A PREPOČÍTAŤ
            </small>
            </em>
          </div>

        </div>
        <div class="w-6">
          <InputGroup class="mt-1">
            <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-100">Zoznam skiel
            </InputGroupAddon>
          </InputGroup>
          <div v-for="(fixG,index) in this.store.order.fixes[this.$props.fixKey].fixGlasses" :key="index"
               class="align-items-center justify-content-center w-full surface-card shadow-2 border-round-lg mt-1">
            <CardFixGlass :fixGlassKey="index" :fixKey="this.$props.fixKey"
                          @change-fix-glass="changeFixGlass">
            </CardFixGlass>
          </div>
        </div>
      </div>
    </div>
    <div class="pl-2">
      <div class="flex flex-wrap  align-items-start" style="max-width:200px">
        <a :href="this.previewUrl" target="_blank" v-if="this.store.order.fixes[this.$props.fixKey].id > 0">
          <PrimevueButton class="mt-2 align-items-center justify-content-center" label="Zobraziť výrobný list" severity="info"
                          icon="pi pi-eye" text raised :disabled="this.store.order.fixes[this.$props.fixKey].id === 0"/>
        </a>

        <a :href="this.printUrl" target="_blank">
          <PrimevueButton class="mt-5  align-items-center justify-content-center" label="Tlačiť výrobný list" severity="info"
                          icon="pi pi-print" text raised :disabled="this.store.order.fixes[this.$props.fixKey].id === 0"/>
        </a>
        <div v-if="this.store.order.fixes[this.$props.fixKey].id === 0">
          <em>Pre tlač výrobných listov prejdite na zoznam objednávok a potom zvovu kliknite na danú objednávku.</em>
        </div>

        <PrimevueButton class="mt-5 align-items-center justify-content-center" label="Vymaž fix" severity="danger"
                        icon="pi pi-trash" text raised @click="deleteFix(this.$props.fixKey)"/>
      </div>

      <iframe :id="`iFramePrintPdf-`+ this.store.order.fixes[this.$props.fixKey].id" style="display:none;" ></iframe>
    </div>
  </div>

  <ModalProfileList v-model:visible="visibleProfileList" modal :header="this.isBinderProfile ? 'Zoznam typov priečnikových profilov' :'Zoznam typov rámových profilov' " :style="{ width: '40rem' }" 
                    closeOnEscape="true" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    
    <div class="pt-3 ">
      <div v-for="(profile,index) in this.profileList" :key="index"
          class="surface-card shadow-2 border-round-lg mt-1">
        <CardProfile v-bind:profile="profile"></CardProfile>
      </div>      
    
    </div>
    <template #footer>
      <!--<div class="mt-3 flex justify-content-center surface-border border-top-2 pt-2"> -->
          <PrimevueButton text raised label="Zavrieť" severity="secondary" @click="visibleProfileList = false" ></PrimevueButton>
      <!-- </div> -->
    </template>
  </ModalProfileList>
</template>

<script lang="ts">

import Security from '../security';
//import notie from 'notie/dist/notie' 
import {store} from '../store';
import RowInput from "@/components/forms/RowInput.vue";
import PrimevueButton from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputSwitch from "primevue/inputswitch";
import CardFixBinder from "@/components/forms/CardFixBinder.vue";
import CardFixGlass from "@/components/forms/CardFixGlass.vue";
import CardProfile from "@/components/forms/CardProfile.vue";
import ModalProfileList from 'primevue/dialog';
import {FixBinder, Models} from "@/components/models";

export default {
  name: 'CardFix',
  components: {
    RowInput,
    PrimevueButton,
    Dropdown,
    CardFixBinder,
    CardFixGlass,
    ModalProfileList,
    CardProfile,
    InputSwitch
  },
  props: {
    //fix: {type: Object},
    fixKey: Number,
    validationOK: Boolean,
    validationAttr: String,

  },
  data() {
    return {
      store,
      //myFix: this.$props.fix,
      opening: Models.Opening.SLIDING,
      ramedProfileId: String,
      binderProfileId: String,
      pdfUrl: String,
      printUrl: String,
      previewUrl: String,
      visibleProfileList: false,
      profileList: [],
      isBinderProfile: Boolean,
    }
  },
  methods: {
    visibleProfiles(binder:boolean){
      this.visibleProfileList = true;
      if(binder){
        this.profileList = this.store.binderProfiles;
        this.isBinderProfile = true
      }else{
        this.profileList = this.store.profiles;
        this.isBinderProfile = false        
      }
    },
    changeInput(data: any): void {
      //console.log("ChangeInput data:", data);
      //console.log("fixes:", this.store.order);

      this.unsubscribe = this.$emit('change-fix', {
        fixKey: this.$props.fixKey,
        fixName: data.name,
        fixValue: data.value
      })

      this.pdfUrl = this.store.Api_url + "/pdf/fp/" + this.store.order.fixes[this.$props.fixKey].id
      this.previewUrl = this.pdfUrl + "?format=screen"
      this.printUrl = this.pdfUrl + "?format=print"

    },
    deleteFix(index: number): void {
      this.unsubscribe = this.$emit('delete-fix', index)
    },

    changeProfile(data: any): void {
      if (Number(data.value) > 0) {
        let frameProfile = this.store.profiles.filter(obj => obj.id == Number(data.value))[0]
        if (typeof frameProfile == undefined) {
          this.$emit('errorProfile', "Chyba pri nacitani profilu");
          return;
        }else{
          // make sure, the string ID is represented as int sent within JSON object fired to backend
          frameProfile.id = Number(data.value)
          this.ramedProfileId = data.value
          this.store.order.fixes[this.$props.fixKey].profile = frameProfile;
        }
      }
      this.unsubscribe = this.$emit('change-fix')
    },

    changeBinderProfile(data: any): void {
      if (Number(data.value) > 0) {
        let binderProfile = this.store.binderProfiles.filter(obj => obj.id == Number(data.value))[0]
        if (typeof binderProfile == undefined) {
          this.$emit('errorProfile', "Chyba pri nacitani profilu");
          return;
        }else{
          binderProfile.id = Number(data.value)
          this.binderProfileId = String(data.value)
          this.store.order.fixes[this.$props.fixKey].binderProfile = binderProfile;
        }
      }
      this.unsubscribe = this.$emit('change-fix')
    },

    changeSelect(data: any): void {
      this.unsubscribe = this.$emit('change-fix',{
        fixKey: this.$props.fixKey,
        fixName: data.name,
        fixValue: data.value
      })
    },

    changeFixBinder(data: any): void {
      if (data.fixBinderKey == "leftOffset") {
        this.store.order.fixes[this.$props.fixKey].fixBinders[data.fixBinderKey].leftOffset = data.fixBinderValue;
      }
      if (data.fixBinderKey == "rightOffset") {
        this.store.order.fixes[this.$props.fixKey].fixBinders[data.fixBinderKey].rightOffset = data.fixBinderValue;
      }
      if (data.fixBinderKey == "lockRightOffset") {
        this.store.order.fixes[this.$props.fixKey].fixBinders[data.fixBinderKey].lockRightOffset = data.fixBinderValue;
      }
      if (data.fixBinderKey == "lockLeftOffset") {
        this.store.order.fixes[this.$props.fixKey].fixBinders[data.fixBinderKey].lockLeftOffset = data.fixBinderValue;
      }
      this.unsubscribe = this.$emit('change-fix')
    },
    changeFixGlass(data: any): void {
      this.store.order.fixes[this.$props.fixKey].fixGlasses[data.fixGlassKey].name = data.fixGlassValue;
      this.unsubscribe = this.$emit('change-fix')
    },
    calculateBinderOffsets(): void {
      const totalWidth = this.store.order.fixes[this.$props.fixKey].width;
      let remainingWidth = this.store.order.fixes[this.$props.fixKey].width;
      const binderTotalCount = this.store.order.fixes[this.$props.fixKey].fixBinders.length;
      let binderCount = this.store.order.fixes[this.$props.fixKey].fixBinders.length;

      console.log('total width: ', remainingWidth);

      for (let i = 0; i < binderTotalCount; i++) {
        let binder = this.store.order.fixes[this.$props.fixKey].fixBinders[i] as FixBinder;
        if (i == 0) {
          if (binder.lockLeftOffset) {
            remainingWidth -= Number(binder.leftOffset);
            binderCount--;
          }
        }
        if (binder.lockRightOffset) {
          let offst = Number(binder.rightOffset);
          if (Number(binder.rightOffset) == 0) {
            offst = Number(binder.leftOffset);
          }
          remainingWidth -= offst;
          binderCount--;
        }
      }

      console.log('remainingWidth width, without lock: ', remainingWidth);

      const middleBinderOffset = Math.round(remainingWidth / (binderCount + 1))
      let assignedWidth = 0;
      // reassign leftoffest

      for (let i = 0; i < binderTotalCount; i++) {
        let binder = this.store.order.fixes[this.$props.fixKey].fixBinders[i] as FixBinder;
        if (i == 0) {
          if (binder.lockLeftOffset) {
            assignedWidth += Number(binder.leftOffset)
            console.log('assignedWidth after left lock: ', assignedWidth);
          }
        }
        // handle middle binders
        if (!binder.lockLeftOffset && !binder.lockRightOffset) {
          binder.leftOffset = middleBinderOffset
          assignedWidth += middleBinderOffset
          console.log('assignedWidth after a binder: ', assignedWidth);
        }
        // handle last binder - same as middleBinderOffset
        if (i == (binderTotalCount - 1) && binder.lockRightOffset) {
          binder.leftOffset = middleBinderOffset
          assignedWidth += middleBinderOffset

          console.log('check remaining: ', totalWidth - assignedWidth);

          const remCheck = totalWidth - assignedWidth;

          if (remCheck > Number(binder.rightOffset)) {
            binder.leftOffset += remCheck - Number(binder.rightOffset);
          }

          console.log('leftOffset for last locked binder: ', binder.leftOffset);
        }


        this.store.order.fixes[this.$props.fixKey].fixBinders[i] = binder
      }
      this.unsubscribe = this.$emit('change-fix')
    },
    async printTrigger() {
      // this method converting url to blob does not work on localhost with different API domains, it may work on prod!
      const fixId = this.store.order.fixes[this.$props.fixKey].id;
      const elementId = "iFramePrintPdf-" + fixId;

      const printUrl = this.store.Api_url + '/pdf/fp/' + fixId + '?format=print';

      // const response = await fetch(printUrl);
      // const pdfData = await response.blob(); // Get the PDF data as blob

      const iframePrintPdf = document.getElementById(elementId) as HTMLIFrameElement;
      if (iframePrintPdf) {
        // const url = URL.createObjectURL(pdfData); // Create a temporary URL for the blob
        const url = printUrl;
        console.log(url);

        // Introduce a 1-second delay before setting the iframe source
        await new Promise(resolve => setTimeout(resolve, 1000));

        iframePrintPdf.src = url;

        // Optional: Focus and print (might require user interaction)
        iframePrintPdf.focus();
        if (iframePrintPdf.contentWindow) {
          iframePrintPdf.contentWindow.print(); // Might not work in all browsers
        }
        console.log(iframePrintPdf);
        // this.$refs[`print-spinner-${fixId}`].hidden = true;
        URL.revokeObjectURL(url); // Revoke temporary URL after use
      }
    },
  },
  beforeMount() {
    Security.requireToken();
    this.ramedProfileId = String(this.store.order.fixes[this.$props.fixKey].profile.id)
    this.binderProfileId = String(this.store.order.fixes[this.$props.fixKey].binderProfile.id)
    this.pdfUrl = this.store.Api_url + "/pdf/fp/" + this.store.order.fixes[this.$props.fixKey].id
    this.previewUrl = this.pdfUrl + "?format=screen"
    this.printUrl = this.pdfUrl + "?format=print"
  }
}

</script>

<style scoped>
button {
  border-radius: 6px;
}

.p-button {
  padding: 0.4rem 0.7rem;
}
.help-button{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 4px;
  height: 2.1rem; 

}
.p-inputgroup {
  height: 2rem; 
  /*margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;*/
}
.p-inputgroup-addon {
  padding: 1rem 0.5rem;
 }
 .p-dropdown {
  height: 2.1rem;
  padding: 0.2rem;
  background: #b8d6f0;
 }
 .p-inputtext{
  background: #b8d6f0; 
  text-align: left;
  height: 2.1rem; 
}
.p-inputnumber-input {
  height: 2.1rem;
}


</style>