import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_fix_input = _resolveComponent("form-fix-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.orderLoaded && !this.isFix)
      ? (_openBlock(), _createBlock(_component_form_input, { key: 0 }))
      : _createCommentVNode("", true),
    (this.orderLoaded && this.isFix)
      ? (_openBlock(), _createBlock(_component_form_fix_input, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}