<template>
  <footer class="d-flex align-items-center justify-content-center mt-5">
  Copyright &copy; {{new Date().getFullYear()}}
  </footer>
</template>

<style scoped>
footer{
  background: #333;
  color: #FFF;
  min-height: 3em;
}
</style>