<template>
    <div class="mb-3">
        <label :for="id" class="form-label">{{label}}</label>
        <input
            :type="type"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            :required:string="required"
            :min="min"
            :max="max"
            :value="modelValue"
            :autocomplete="name + '-new'"
            @input="$emit('update:modelValue',($event.target as any).value)"
            class="form-control">
    </div>
</template>

<script lang="ts">
export default {
    name: 'TextInput',
    props: {
        name: String,
        type: String,
        id: String,
        label: String,
        placeholder: String,
        required: String,
        min: String,
        max: String,
        modelValue: String,
    },
}
</script>