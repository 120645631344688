<template>
  <div class="form-check mb-3">
    <input class="form-check-input" v-model="isChecked" :required="required" type="checkbox" :value="value" :name="name" :id="name">
    <label class="form-check-label" :for="name">
      {{label}}
    </label>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';

export default {
  name: 'CheckInput',
  props: {
    label: String,
    required: Boolean,
    name: String,
    value: [String, Number] as PropType<string | number>,
    checked: Boolean,
  },
  data() {
    return {
      isChecked: this.checked,
    };
  },
  watch: {
    // Watch for external changes to 'checked' prop and update local state
    checked(newVal) {
      this.isChecked = newVal;
    }
  }
}
</script>
