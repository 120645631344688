import {store} from './store'
//import router from './../router/index'
import {Models} from './models'
import Security from './security'
import notie from 'notie/dist/notie';

const Utils:any = {
    getStateSeverity(reference: Models.State): string {
        switch (reference) {
          case Models.State.SENT:
            return "danger";
          case Models.State.PRODUCTION:
            return "warning";
          case Models.State.READY:
            return "success";
          case Models.State.FINISH:
            return "contrast";
          default:
            return "info";
        }
    },
    parseToSlovakDateTime(dateTimeStr) {
        // Parse the datetime string into a Date object
        let date = new Date();
        if (typeof dateTimeStr == "undefined"){
          date = new Date();
        }else{
           date = new Date(dateTimeStr);
        }
        date = this.getCETorCESTDate(date)
        //console.log(dateTimeStr)
        // Define options for formatting the date
        const dateOptions: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: '2-digit',
          day: '2-digit',
      //    hour: '2-digit',
      //    minute: '2-digit',
      //    second: '2-digit', // Include seconds for datetime
        };
  
        // Define options for formatting time according to Slovak locale
        const timeOptions: Intl.DateTimeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
        };
  
        // Using 'sk-SK' locale for date
        const formattedDate = new Intl.DateTimeFormat('sk-SK', dateOptions).format(date);
  
        // Using 'sk-SK' locale for time
        const formattedTime = new Intl.DateTimeFormat('sk-SK', timeOptions).format(date);
       
        // Combine formatted date and time with separator (e.g., " ")
        return `${formattedDate.replace(/\s/g, "")} ${formattedTime}`;
    },
    formatToSlovak(dateStr){
        let date = new Date(dateStr);
        date = this.getCETorCESTDate(date);

        const dateOpt: Intl.DateTimeFormatOptions = {
          year: "numeric",  // Display year as a number (e.g., 2024)
          month: '2-digit',  // Display month with two digits (e.g., 03 for March)
          day: '2-digit',   // Display day with two digits (e.g., 14)
          hour: '2-digit',   // Display hour with two digits (e.g., 13 for 1 PM)
          minute: '2-digit', // Display minute with two digits (e.g., 48)
          hour12: false,     // Use 24-hour format (not AM/PM)
        };
  
  
        // Using 'sk-SK' locale
        return new Intl.DateTimeFormat('sk-SK', dateOpt).format(date);
    },
    parseToSlovakDate(dateTimeStr) {
        //console.log('parseToSlovakDate:',dateTimeStr)
        const date = new Date(dateTimeStr);
  
        // Define options for formatting the date
        const dateOptions: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: '2-digit',
          day: '2-digit',
        };
        const formattedDate = new Intl.DateTimeFormat('sk-SK', dateOptions).format(date);
        
        return `${formattedDate}`.replace(/\s/g, "");
    },
    createDateFromString(dateString: string): Date | null {
        // Split the date string into day, month, and year components
        const [dayString, monthString, yearString] = dateString.split(".");
      
        // Parse the components into integers
        const day = parseInt(dayString, 10);
        const month = parseInt(monthString, 10) - 1; // Adjust month (January = 0)
        const year = parseInt(yearString, 10);
      
        // Validate the date components
        if (
          isNaN(day) ||
          isNaN(month) ||
          isNaN(year) ||
          day < 1 ||
          day > 31 ||
          month < 0 ||
          month > 11 ||
          year < 0
        ) {
          return null; // Invalid date format or values
        }
      
        // Create the date object
        const date = new Date(year, month, day);
      
        // Check if the parsed date object matches the original format (avoid edge cases)
        if (
          date.getDate() !== day ||
          date.getMonth() !== month ||
          date.getFullYear() !== year
        ) {
          return null; // Invalid date due to edge cases (e.g., Feb 30)
        }
      
        return date;
    },
    saveDate(newDate): Date {
        if (typeof newDate == "string"){
            newDate.replace(/\s/g, "");
            newDate = this.createDateFromString(newDate);
            //newDate = new Date(newDate);
            //console.log('New Date:',newDate)
        }
        const newDateTemp = new Date(newDate.getTime()); 
        newDateTemp.setDate(newDateTemp.getDate() + 1);
        //console.log('SAve Date:',newDateTemp)
        return newDateTemp;
    },
    updateOrderHistory(idOrder:number) {
        //fetch order history
        //console.log('order history:',idOrder)
        fetch(store.Api_url + "/v1/order-history?query=order_id:" + String(idOrder), Security.requestOptions("GET"))
            .then((response) => response.json())
            .then((responseData) => {
              if (responseData.error) {
                //console.log("error:", responseData.message);
                notie.alert({
                  type: 'error',
                  text: 'Nepodarilo sa načítať prehľad stavov',
                  position: "bottom"
                })          
              } else {
                
                if(responseData.data != null){
                  store.orderHistory = responseData.data;
                  //console.log('order history:');
                  //console.log(store.orderHistory)
                } else{
                  //console.log('there is no order history ');
                  store.orderHistory = []
                }
  
                this.updateStatuses();
              }
            });
    },
    changeState(s: Models.State){
        if(confirm("Naozaj chcete zmeniť stav?")){
          fetch(store.Api_url + "/set-state/" + store.order.id+ "/" + s, Security.requestOptions("POST"))
              .then((response) => response.json())
              .then((responseData) => {
                if (responseData.error) {
                  console.log("error:", responseData.message);
                  notie.alert({
                    type: 'error',
                    text: 'Stav objednávky sa nepodarilo zmeniť',
                    position: "bottom"
                  })          
                } else {
                  store.orderHistory = responseData.data;
                  this.updateStatuses();
                  const lastHistory = store.orderHistory[store.orderHistory.length - 1];
                  store.order.currentState = lastHistory.state;
                }
              });
        }
  
    },
    updateStatuses(){
        store.hasBeenSent = this.historyContainsStatus(Models.State.SENT) as Boolean
        store.hasBeenInProduction = this.historyContainsStatus(Models.State.PRODUCTION) as Boolean
        store.hasBeenReady = this.historyContainsStatus(Models.State.READY) as Boolean
        store.hasBeenFinished = this.historyContainsStatus(Models.State.FINISH) as Boolean
    },
    historyContainsStatus(s: Models.State) {
        if(store.orderHistory != null){
          return store.orderHistory.some((history : Models.OrderHistory) => history.state.reference === s)
        }else {
          return false
        }
    },
    getOrderStates() {
        fetch(store.Api_url + "/v1/order-state", Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.error) {
              console.log("error:", responseData.message);
              //this.$emit('error', responseData.message);
            } else {
      
              store.orderStates = responseData;

              if (store.order.id === 0) {
                  store.order.currentState = store.orderStates[0]
              }
            }
        });
    },

    getEmailHistory(idOrder:number) {
      //fetch order history
      //console.log('order history:',idOrder)
      fetch(store.Api_url + "/v1/email-history?query=order_id:" + String(idOrder), Security.requestOptions("GET"))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error) {
              console.log("error:", responseData.message);
              notie.alert({
                type: 'error',
                text: 'Nebolo možné načítať históriu emailov',
                position: "bottom"
              })          
              //this.$emit('error', responseData.message);
            } else {
              
              if(responseData.data != null){
                store.emailHistories = responseData.data;
                store.hasBeenEmailSent = true;
                //console.log('order history:')
                //console.log(store.orderHistory)
              } else{
                store.hasBeenEmailSent = false;
                store.emailHistories = [];
                console.log('there is no email history ')
              }

              //this.updateStatuses();
            }
          });
    },

    changeEmailHistory(){
      fetch(store.Api_url + "/v1/email-history", Security.requestOptions("POST", store.emailHistory))
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.error) {
          console.log("error:", responseData.message);
          notie.alert({
            type: 'error',
            text: 'Údaje o zaslanom emaile neboli uložené do histórie',
            position: "bottom"
          })          
        } else {
          //console.log("Email History:", responseData);
          this.getEmailHistory(responseData.order.id) 
          
          //store.emailHistories = responseData.filter(obj => obj.order.id == store.order.id);

          //store.hasBeenEmailSent = true;
        }
      });   
    },
    sendingEmail(type: string,id: number){
      fetch(store.emailLink + "?type="+type+"&recipId=" + id,Security.requestOptions("GET"))
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.error) {
          notie.alert({
              type: 'error',
              text: responseData.message,
              position: "bottom"
            })
         // this.$emit('error', responseData.message);
        } else {
  
          store.emailHistory.createDate = new Date();
          //console.log("Email History Date:", store.emailHistory.createDate);
          store.emailHistory.order = store.order;
          store.emailHistory.subject = store.emailRecipient.glassType;
          store.emailHistory.user = store.user;
          store.emailHistory.whom = store.emailRecipient.name;
          
          Utils.changeEmailHistory()
          
          notie.alert({
                type: 'success',
                text: responseData.message,
                position: "bottom"
          });
        }
      })
    },
    // check, či pre daný dátum platí zimný, či letný čas
    isDSTInEffect(date: Date): boolean {
      // Create a new Date object with the same date but at 12:00 AM
      const midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

      // Get the time zone offset in milliseconds
      const timezoneOffset = midnight.getTimezoneOffset();

      // Check if the offset is one hour more than the standard CET offset (GMT+01:00)
      return timezoneOffset === -60; // 60 = 1 hour
    },
    getCETDateAndTime(date: Date): Date{
      const utcDate = date.toUTCString();
    
      // Create a new Date object with UTC time and specify CET time zone
      return new Date(utcDate + " GMT-01:00");
    },
    getCESTDateAndTime(date: Date): Date{
      const utcDate = date.toUTCString();
         
      return new Date(utcDate + " GMT-02:00");
    },  
    getCETorCESTDate(date: Date):Date{

      if(this.isDSTInEffect(date)){
        return this.getCETDateAndTime(date)
      }else{
        return this.getCESTDateAndTime(date)
      }
    },
    minusOneHour(date: Date): Date {
      // Get the current hour
      const currentHour = date.getHours();
      // Calculate the new hour by adding one hour
      const newHour = (currentHour - 1) % 24; // Modulo 24 to keep the hour within the range 0-23
       // Set the new hour in the Date object
      date.setHours(newHour);
      // Return the modified Date object
      return date;
    }
}

export default Utils;