<script lang="ts">
import {store} from "@/components/store";
import FormInput from "@/components/forms/FormInput.vue";
import FormFixInput from "@/components/forms/FormFixInput.vue";
import Security from "@/components/security";
import notie from "notie/dist/notie";

export default {
  components: {
    FormInput,
    FormFixInput,


  },
  name: "OrderDetail",
  data() {
    return {
      store,
      orderLoaded: false,
      isFix: false,
    }
  },
  beforeMount() {
    console.log("calling user")
    Security.requireToken();

    if (parseInt(String(this.$route.params.orderId), 10) > 0) {
      // fetch order and populate store object
      const orderId = String(this.$route.params.orderId)

      fetch(store.Api_url + "/v1/order/" + String(orderId), Security.requestOptions("GET"))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error) {
              console.log("error:", responseData.message);
              this.$emit('error', responseData.message);
              notie.alert({
                type: 'warning',
                text: "Objednávku sa nepodarilo načítať",
                position: "top"
              })
            } else {
              store.order = responseData.data
              store.type = store.order.type
              if(store.type.code == "FIX"){
                this.isFix = true;
              }
              
              this.orderLoaded = true

              console.log("nacitany objekt v store:")
              console.log(store.order)

            }
          })
          .catch((error) => {
            alert('chyba pri nacitani objednavok: ' + error)
          })
    }
  }
}
</script>



<style scoped>

</style>

<template>
  <form-input v-if="this.orderLoaded && !this.isFix"></form-input>
  <form-fix-input v-if="this.orderLoaded && this.isFix"></form-fix-input>
</template>