<template>
    <InputGroup v-if="type == 'select' || type == 'number' || type == 'text'" class=" mt-1 mb-1"
        :class="`${validationAlert===true ? 'border-solid border-red-900 border-round':''}`">
        <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start" :class="backgroundText" >{{labelFront}}</InputGroupAddon>
        <InputNumber v-if="type == 'number'"
            class="w-3 justify-content-end"
            inputStyle="background: #b8d6f0; text-align: right"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            :required:string="required"
            v-model="this.value"
            :autocomplete="name + '-new'"
            >
        </InputNumber>
        <InputText v-if="type == 'text'" 
            class="justify-content-end"
            :class="`${validationAlert ? 'bg-red-300' : name !=='openingDirection' && name !== 'profilesColor' ? 'w-2' : 'w-5'}`"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            :required:string="required"
            v-model="this.value"
            :autocomplete="name + '-new'"
            >
        </InputText>

        <Dropdown v-if="type == 'select'"  
            v-model="this.value"
            :options="option"
            optionLabel="name"
            optionValue="code"
            :placeholder="this.value"
            :class="`${name !=='openingDirection' && name !== 'profilesColor' ? 'w-2' : 'w-5'}`" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem" />

        <InputGroupAddon v-if="labelBack !== '[-]'"
                         class="font-normal text-sm w-2"
                        :class="`${validationAlert === true ? 'bg-red-300':''}`">{{labelBack}}</InputGroupAddon>
    </InputGroup>

  <InputGroup v-if="type == 'text-name'" class="h-2rem mt-1 mb-1"
              :class="`${validationAlert===true ? 'border-solid border-red-900 border-round':''}`">
    <InputGroupAddon class="w-4 font-normal text-base text-700 justify-content-start" :class="backgroundText" >{{labelFront}}</InputGroupAddon>

    <InputText
               class="justify-content-end"
               :class="`w-4`"
               inputStyle="`${validationAlert ? 'background: red;' : 'background: #b8d6f0;'`  text-align: right"
               :name="name"
               :id="id"
               :placeholder="placeholder"
               :required:string="required"
               v-model="this.value"
               :autocomplete="name + '-new'"
    >
    </InputText>

  </InputGroup>

    <Dropdown v-if="type == 'select-opening'"
              v-model="this.value"
              :options="option"
              optionLabel="name"
              optionValue="code"
              :placeholder="this.value"
              class="mb-5" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem" />

    <InputGroup v-if="type == 'select-select'" class=" mt-2">
        <InputGroupAddon class="w-6.9rem font-normal text-base text-700 justify-content-start">Druh skiel</InputGroupAddon>
        <InputGroupAddon class="w-4rem font-normal text-sm text-700 pl-1 pr-2">{{labelFront}}</InputGroupAddon>
        <Dropdown
                v-model="this.value"
                :options="option"
                optionLabel="name"
                optionValue="code"
                :placeholder="this.value"
                class="w-4rem" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem" />
        <InputGroupAddon class="w-3.9rem font-normal text-sm text-700 pl-1 pr-2" >{{labelBack}}</InputGroupAddon>
        <Dropdown
                v-model="this.value2"
                :options="this.option2"
                optionLabel="name"
                optionValue="code"
                :placeholder="this.value2"
                class="w-7rem" inputStyle="padding-left: 0.3rem; padding-top: 0.1rem" />
    </InputGroup>

    <InputGroup v-if="type == 'wall-comment'" class="">
      <TextArea autoResize rows="2" class="w-full"
                placeholder="Poznámka"
                v-model="this.value"
      />
    </InputGroup>

    <InputGroup v-if="type == 'checkbox'" class="w-full flex align-items-center mt-2 justify-content-start">
        <label for="holes1" class="mr-2 text-700"> {{ labelFront }} </label>
        <Checkbox  class="" v-model="this.value" :binary="true" inputId="holes1"/>
    </InputGroup>

    <InputGroup v-if="type == 'checkbox-number'" class="w-full flex flex-row flex-wrap align-items-center mt-2 justify-content-start">
        <div class="w-4 flex align-items-center h-2rem justify-content-start">
            <label for="fix" class="mr-2 w-7 text-700"> {{ labelFront }} </label>
            <Checkbox  class="" v-model="this.value" :binary="true" inputId="fix"/>
        </div>
        <InputGroup v-if="this.value == true" class="w-8 flex">
            <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start" >{{label}}</InputGroupAddon>
            <InputNumber class="w-4 justify-content-end" inputStyle="background: #b8d6f0; text-align: right"
                inputId="integeronly"
                :name="name"
                :id="id"
                :placeholder="placeholder"
                :required:string="required"
                v-model="this.value2"
                :autocomplete="name + '-new'"
                >
            </InputNumber>
            <InputGroupAddon v-if="labelBack !== '[-]'" class="font-normal text-sm w-3" >{{labelBack}}</InputGroupAddon>
        </InputGroup>
    </InputGroup>


</template>

<script lang="ts">

import Dropdown from 'primevue/dropdown';
//import {emiter} from '../../main';
import Checkbox from 'primevue/checkbox';
import {store} from '../store';
import TextArea from "primevue/textarea";

export default {
    name: 'RowInput',
    components:{
      TextArea,
        Dropdown,
        Checkbox,
    },
    props: {
        name: String,
        name2: String,
        id: String,
        labelFront: String,
        label: String,
        labelBack: String,
        placeholder: String,
        required: String,
        defaultValue: {type:[Number,String,Boolean]},
        defaultValue2: {type:[Number,String,Boolean]},        
        type:String,
        option: Array,
        option2: Array,
        backgroundText: String,
      validationAlert: Boolean,
    },
    data() {
        return {
            store,
            value: this.$props.defaultValue,
            value2: this.$props.defaultValue2
        }
    },
  watch: {
    value(newValue) {
      this.$emit('new-wall-value', {name: this.name, value: newValue});
    },
    value2(newValue) {
      this.$emit('new-wall-value', {name: this.name2, value: newValue});
    }
  },
    mounted() {
       
        //console.log("props:", this.$props.defaultValue)
    },
    methods:{
      OnInput() :void {
       // console.log('rowInput:', this.name, " = ", this.value)
        this.unsubscribe = this.$emit('new-wall-value',{name:this.name,value:this.value})
      },
      OnInput2() :void {
        //console.log("value:", this.value)
        this.unsubscribe = this.$emit('new-wall-value',{name:this.name2,value:this.value2})
      },
      myNumber(number:number): String{
            return number?.toString();
        },
    },

}
</script>

<style scoped>
  .p-inputgroup {
    height: 2rem; 
  }
  .p-inputgroup-addon {
   padding: 1rem 0.5rem;
   height: 2rem; 
  }
  .p-dropdown {
   height: 2.1rem; 
   padding: 0.2rem;
   background: #b8d6f0;
  }
  .p-inputtextarea {
    padding: 0.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .p-inputtext{
    background: #b8d6f0; 
    text-align: left;
    height: 2.1rem; 
  }
  .p-inputnumber-input {
      height: 2.1rem;
  
  }
  .p-inputnumber {
    height: 2.1rem;

}

</style>