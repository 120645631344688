export namespace Models {
    export enum TypeCode {
        B_F = "B-F",         // Čelné zasklenie
        B_F_L = "B-F-L",     // Čelné zasklenie + Bočné ľavé
        B_F_R = "B-F-R",     // Čelné zasklenie + Bočné pravé
        B_F_H = "B-F-H",     // Čelné zasklenie + Diery pre vysoké steny
        B_U = "B-U",         // Celkové zasklenie
        NB_F = "NB-F",       // Bezbariérové čelné zasklenie
        NB_F_L = "NB-F-L",   // Bezbariérové čelné zasklenie + Bočné ľavé
        NB_F_R = "NB-F-R",   // Bezbariérové čelné zasklenie + Bočné pravé
        NB_F_D = "NB-F-D",   // Bezbariérové čelné zasklenie s opačnými dverami
        FIX = "FIX",
        DEFAULT = ""         // Default constant for the NULL value
    }

    export enum Position {
        FRONT = "FRONT",
        LEFT = "LEFT",
        RIGHT = "RIGHT",
    }

    export enum Opening {
        FIXED = "FIXED",
        SLIDING = "SLIDING",
        COMBINED = "COMBINED",
    }

    export enum OpeningDirection {
        LEFT = "LEFT",
        RIGHT = "RIGHT",
        BOTH = "BOTH",
        NONE = "NONE"
    }

    export enum Roof{
        ZINK = "ZINK",
        ALUMINIUM = "ALUMINIUM",
        NONE = "NONE",
    }
    export enum CaseType{
        CASE60 = "CASE60",
        CASE90 = "CASE90",
        NONE = "NONE",
    }

    export enum RailingInfill{
        GLASS = "GLASS",
        LEXAN = "LEXAN",
        NONE = "NONE",
    }

    export enum State{
        NEW = "NEW",
        SENT = "SENT",
        PRODUCTION = "PRODUCTION",
        READY = "READY",
        FINISH = "FINISH",
    }

    export type UserType = {
        id: number,
        firstName: string,
        lastName: string,
        company?: string,
        phone?: string,
        email: string,
        admin: boolean
    }

    export type LayoutType = {
        id: number,
        name: string,
        code: TypeCode,
        thumbnail: string
    }
    export type OrderState = {
        id: number,
        label:  string,
        reference: Models.State, 
    
    }
    export type OrderHistory = {
        id: number,
        order:  OrderType,
        state: OrderState,
        createDate: Date,
        user:UserType,
    }
    export type EmailHistory = {
        id: number,
        order:  OrderType,
        whom: string,
        subject: string
        createDate: Date,
        user:UserType,
    }
    export type EmailRecipient = {
        id: number,
        name: string,
        email: string,
        glassType: string,
    }    
}
// Example usage:
// let myType: TypeCode = TypeCode.B_F;
// console.log(myType);  // Output: B-F

export interface UserType {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    admin: boolean
}

export interface OrderState {
    id: number,
    label:  string,
    reference: Models.State,
}

export interface BksGlass  {
	id : number,
	wall :  WallType,      
	order : OrderType,     
	position : number,
	width : number,  
	height : number, 
	profileOffsetLeft : number,
	profileOffsetRight : number,
	holes : boolean, // It is used in high glasses > 2m.
	holeDistanceTopLeft : number,
	holeDistanceTopRight : number
}


export interface WallType {
    id: number,
    opening: Models.Opening,
    position: Models.Position,
    width: number,
    height: number,
    framesCount: number,
    glassTemperedThickness?: string,
    glassConnexType?: string,
    glassConnexThickness?: string,
    caseType?: Models.CaseType,
    caseThickness?: number,
    anotherProfilesThickness?: number,
    capType?: number,
    openingDirection: Models.OpeningDirection, // if it is fixed glass, set to NONE
    profilesColor?: string,
    glazingType?: string,
    glazingTypeAttribute?: string,
    profilesLength?: number,
    singleProfileLength?: number,
    manualSingleProfileLength?: number,
    totalProfilesLength?: number,
    sideGlassWidth?: number,
    sideGlassCount?: number,
    middleGlassWidth?: number,
    middleGlassCount?: number,
    fixedGlassWidth?: number,
    fixedGlassHeight?: number,
    fixedHorizontalSplits?: number,
    fixedVerticalSplits?: number
    computedGlassHeight?: number,

    fixLeft?:boolean,
    fixLeftWidth?:number,
    fixLeftProfileWidth?:number,
    fixLeftGlassWidth?:number,

    fixRight?:boolean,
    fixRightWidth?:number,
    fixRightProfileWidth?:number,
    fixRightGlassWidth?:number,

    centerRailingWidth?:number,

    combined?: boolean, // the wall main contains fixed part too
    barrier?: boolean, // barrier = true, when wall has SLIDING opening and layout type is B_ type
    bks?: boolean, // bks = true, when wall has SLIDING opening and layout type is NB_ type
    bksGlasses?: BksGlass[],
    holes?: boolean, // optional, when bks is set as true. It is used in high glasses > 2m.
    handle?: boolean, // optional, when bks is set as true. It is used for door handle
    customDoor?: boolean,
    customDoorProfileWidth? :number,
    doorPosition?: string,
    comment?: string
}

export interface OrderType {
    id: number,
    currentState?: Models.OrderState,
    orderNumber?: number,
    customerName: string,
    user?: Models.UserType,
    modifiedUser?: Models.UserType,
    address: string
    floor?: number,
    phone: string
    email: string
    installationDate?: Date
    type: Models.LayoutType, // Reference to the Type object
    roof?: Models.Roof,
    roofDimA? : number,
    roofDimB? : number,
    parapet?:boolean,
    parapetDimA? : number,
    parapetDimB? : number,
    railingReplace?:boolean, // indicates a brand-new railing, not just infill
    railingInfill?: Models.RailingInfill,
    railingComment?: string,
    consoles?: number,
    comment?: string,
    additions?: string,
    walls: WallType[],
    glassesComment: string,
    orderNumYear: number,
    orderNumCode: number,
    fixes: FixType[],
    modifiedDate?: any
}
export interface ProfileType {
    id:   number,
    name: string,
	glassReduction: number,
    cutReduction: number,
    thumbnail: string,
    thumbnail_dim: string,
    width: number,
    binder: boolean,
    active?: boolean, 
}

export interface FixBinder {
    id:         number,
    order :     OrderType,
    profile:    ProfileType,
    fix:        FixType,
    width:      number,
	heightA:    number,
	heightB:    number,
	position?:  number,
	leftOffset?: number,
    rightOffset?: number,
    lockLeftOffset?:number,
    lockRightOffset?:number,
}

export interface FixGlass {
    id:         number,
    order :     OrderType,
    fix:        FixType,
    name?:       string,
    width:      number,
	heightA:    number,
	heightB:    number,
	position?:  number,
    door?: boolean,
}

export interface FixType {
    id:         number,
    order :     OrderType,
    profile:    ProfileType,
    glassType:  string,
    width:      number,
    atypical?:    boolean,
    heightA:    number,
    heightB:    number,
    name?:      string,
    desc?:      string,
    area?:      number,
    price?:     number,
    active?:    boolean,
    binderProfile: ProfileType,
    binderCount: number,
    fixBinders:  FixBinder[],
    fixGlasses:  FixGlass[],
    deletedDate?: Date,
    angle: number,

}
export interface OptionType {
    name:         string,
    code :        string,
}
