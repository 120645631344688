<template>
<div class="border-round-lg border-1 border-cyan-500">

    <div class="surface-border border-round-top-lg justify-content-center bg-cyan-300">
         <h6 class="text-center uppercase">Sklo  #{{(this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].position+1)}}</h6>
    </div>
    <div class="p-1">
      <row-input name="name"
                 id="name"
                 required="true"
                 labelFront="Typ skla"
                 :defaultValue="this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].name"
                 type="text-name"
                 ref="name"
                 inputStyle="background: #b8d6f0; text-align: left"
                 @new-wall-value="changeInput"
      >
      </row-input>
        <InputGroup class="mt-1">
            <InputGroupAddon class="w-4  font-normal text-base text-700 justify-content-start">Šírka</InputGroupAddon>
            <InputGroupAddon class="w-6  font-normal text-base text-700 ">{{this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].width}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="mt-1">
            <InputGroupAddon class="w-4  font-normal text-base text-700 justify-content-start">
              <span v-if="this.store.order.fixes[this.$props.fixKey].atypical">Výška A</span>
              <span v-else>Výška</span>
            </InputGroupAddon>
            <InputGroupAddon class="w-6  font-normal text-base text-700 ">{{this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].heightA}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="mt-1" v-if="this.store.order.fixes[this.$props.fixKey].atypical">
            <InputGroupAddon class="w-4  font-normal text-base text-700 justify-content-start">Výška B</InputGroupAddon>
            <InputGroupAddon class="w-6  font-normal text-base text-700 ">{{this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].heightB}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>  
    </div> 
</div>
</template>

<script lang="ts">

//import Security from '../security';
//import notie from 'notie/dist/notie' 
import {store} from '../store';
import RowInput from "@/components/forms/RowInput.vue";
//import RowInput from "@/components/forms/RowInput.vue";
//import {Models} from '../models';

export default {
    name: 'CardFixGlass',
    components:{
      RowInput
      //RowInput,

    }, 
    props: {
        fixKey: Number,
        fixGlassKey: Number,
         
    },
    data() {
      return {
        store,
        //ramedProfileId: String,
        //binderProfileId: String,
      }
    },
    methods: {
      changeInput(data:any): void{
        //this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].leftOffset = data.value;
        this.$emit('change-fix-glass',{fixGlassKey:this.$props.fixGlassKey,fixGlassValue:data.value})
      }
    },
}
</script>

<style scoped>

.p-inputgroup {
  height: 2rem; 
  /*margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;*/
}
.p-inputgroup-addon {
  padding: 1rem 0.5rem;
 }
 .p-dropdown {
  height: 2.15rem;
  padding: 0.2rem;
  background: #b8d6f0;
 }
 .p-inputtext{
  background: #b8d6f0; 
  text-align: left;
  height: 2.05rem; 
}
.p-inputnumber-input {
  height: 2.1rem;
}
</style>