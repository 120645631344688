<script lang="ts">
import Security from "@/components/security";
import {store} from "@/components/store";
import FormTag from "@/components/forms/FormTag.vue";
import TextInput from "@/components/forms/TextInput.vue";
import notie from "notie/dist/notie";
import router from "@/router";
import CheckInput from "@/components/forms/CheckInput.vue";

export default {
  name: "UserEdit",
  components: {
    CheckInput,
    'form-tag': FormTag,
    'text-input': TextInput,
  },
  methods: {
    submitHandler() {
      if(!this.validateForm()){
        return
      }

      const userId = parseInt(String(this.$route.params.userId), 10)
      console.log('userId:', userId)

      const payload = {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        company: this.user.company,
        email:this.user.email,
        phone:this.user.phone,
        admin:this.user.admin,
        password:this.user.passwd,
        repassword:this.user.repasswd,
        active:true
      }

      let method = "POST"

      let url = "/v1/user/"

      if (userId > 0){
        method = "PUT"
        url = "/v1/user/" + userId
      }

      fetch(store.Api_url + url, Security.requestOptions(method, payload))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error) {
              console.log("error:", responseData.data.message);
              this.$emit('error', responseData.data.message);
              notie.alert({
                type: 'error',
                text: responseData.data.message
              })
            } else {
              notie.alert({
                type: 'success',
                text: "Užívateľ uložený"
              })
              this.user = responseData.data
              router.push("/admin/users");
            }
          })
          .catch((error) => {
            this.$emit('error', "Chyba pri uložení užívateľa" + error);
            notie.alert({
              type: 'error',
              text: "Chyba pri uložení užívateľa" + error
            })
          })


    },
    validateForm() {
      this.isSubmitted = true; // Mark the form as submitted to show validation feedback
      this.isPhoneValid = this.user.phone.startsWith('+421');
      if(this.isPhoneValid) {
        return true
      }else{
        return false
      }
    },
    handleAdminChange(event) {
      if(event.target.checked === true){
        if(confirm("Povoľujete tomuto užívateľovi plné práva nad systémom?")){
          this.user.admin = event.target.checked;
        }else {
          this.user.admin = false
        }
      }else{
        this.user.admin = false
      }

    }
  },
  data() {
    return {
      store,
      isPhoneValid: true,
      isSubmitted: false,
      checkedAdmin :"",
      user: {
        id: 0,
        firstName: "",
        lastName: "",
        company: ",",
        email: "",
        phone: "",
        passwd: "",
        repasswd: "",
        admin: false
      }
    }
  },
  beforeMount() {
    console.log("calling user")
    Security.requireToken();

    if(store.user.admin===false ){
      router.push("/")
      notie.alert({
        type: 'error',
        text: "Na túto akciu nemáte oprávnenia"
      })
    }

    if (parseInt(String(this.$route.params.userId), 10) > 0) {
      // editing an existing user
      const userId = String(this.$route.params.userId)

      fetch(store.Api_url + "/v1/user/" + userId, Security.requestOptions("GET"))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error) {
              console.log("error:", responseData.data);
              this.$emit('error', responseData.data);
            } else {
              console.log(responseData);
              this.user = responseData.data
              // do not load user passwd
              this.user.passwd = ''
              this.user.repasswd = ''
              if(this.user.admin === true){
                this.checkedAdmin = "checked"
              }
            }
          })
          .catch((error) => {
            this.$emit('error', "Chyba pri načítaní užívateľov" + error);
          })
    }


  }
}
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="mt-3">Detail užívateľa</h1>
      </div>
      <hr>
      <form-tag @userEditEvent="submitHandler" name="userForm" event="userEditEvent">
        <text-input
            v-model="user.firstName"
            type="text"
            required="true"
            label="Meno"
            :value="user.firstName"
            name="first-name"></text-input>
        <text-input
            v-model="user.lastName"
            type="text"
            required="true"
            label="Priezvisko"
            :value="user.lastName"
            name="last-name"></text-input>
        <text-input
            v-model="user.company"
            type="text"
            required="false"
            label="Spoločosť"
            :value="user.company"
            name="company"></text-input>
        <text-input
            v-model="user.email"
            type="email"
            required="true"
            label="E-mail"
            :value="user.email"
            name="email"></text-input>

        <div class="form-group mb-3">
          <label for="phone">Mobil</label>
          <input type="text" class="form-control" id="phone" required v-model="user.phone" :class="{'is-invalid': !isPhoneValid && isSubmitted}">
          <div class="invalid-feedback">
            Mobil musí začínať s '+421'.
          </div>
        </div>

        <text-input
            v-model="user.passwd"
            type="text"
            required="true"
            label="Heslo"
            :value="user.passwd"
            name="passwd"></text-input>
        <text-input
            v-if="this.user.id===0"
            v-model="user.repasswd"
            type="text"
            label="Heslo znovu"
            :value="user.repasswd"
            name="repasswd"></text-input>

        <CheckInput
            label="Správca systému"
            :required="false"
            name="admin"
            value="yes"
            :checked="user.admin"
            @change="handleAdminChange"
        />

        <div class="float-start">
          <input type="submit" value="Uložiť" class="btn btn-primary me-2">
          <router-link to="/admin/users" class="btn btn-outline-secondary">Zrušiť</router-link>
        </div>
      </form-tag>

    </div>
  </div>
</template>

<style scoped>

</style>