import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb0d2048"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  for: "holes1",
  class: "mr-2 text-700"
}
const _hoisted_2 = { class: "w-4 flex align-items-center h-2rem justify-content-start" }
const _hoisted_3 = {
  for: "fix",
  class: "mr-2 w-7 text-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroupAddon = _resolveComponent("InputGroupAddon")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.type == 'select' || $props.type == 'number' || $props.type == 'text')
      ? (_openBlock(), _createBlock(_component_InputGroup, {
          key: 0,
          class: _normalizeClass(["mt-1 mb-1", `${$props.validationAlert===true ? 'border-solid border-red-900 border-round':''}`])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroupAddon, {
              class: _normalizeClass(["w-7 font-normal text-base text-700 justify-content-start", $props.backgroundText])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.labelFront), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            ($props.type == 'number')
              ? (_openBlock(), _createBlock(_component_InputNumber, {
                  key: 0,
                  class: "w-3 justify-content-end",
                  inputStyle: "background: #b8d6f0; text-align: right",
                  name: $props.name,
                  id: $props.id,
                  placeholder: $props.placeholder,
                  "required:string": $props.required,
                  modelValue: this.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.value) = $event)),
                  autocomplete: $props.name + '-new'
                }, null, 8, ["name", "id", "placeholder", "required:string", "modelValue", "autocomplete"]))
              : _createCommentVNode("", true),
            ($props.type == 'text')
              ? (_openBlock(), _createBlock(_component_InputText, {
                  key: 1,
                  class: _normalizeClass(["justify-content-end", `${$props.validationAlert ? 'bg-red-300' : $props.name !=='openingDirection' && $props.name !== 'profilesColor' ? 'w-2' : 'w-5'}`]),
                  name: $props.name,
                  id: $props.id,
                  placeholder: $props.placeholder,
                  "required:string": $props.required,
                  modelValue: this.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.value) = $event)),
                  autocomplete: $props.name + '-new'
                }, null, 8, ["class", "name", "id", "placeholder", "required:string", "modelValue", "autocomplete"]))
              : _createCommentVNode("", true),
            ($props.type == 'select')
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 2,
                  modelValue: this.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.value) = $event)),
                  options: $props.option,
                  optionLabel: "name",
                  optionValue: "code",
                  placeholder: this.value,
                  class: _normalizeClass(`${$props.name !=='openingDirection' && $props.name !== 'profilesColor' ? 'w-2' : 'w-5'}`),
                  inputStyle: "padding-left: 0.3rem; padding-top: 0.1rem"
                }, null, 8, ["modelValue", "options", "placeholder", "class"]))
              : _createCommentVNode("", true),
            ($props.labelBack !== '[-]')
              ? (_openBlock(), _createBlock(_component_InputGroupAddon, {
                  key: 3,
                  class: _normalizeClass(["font-normal text-sm w-2", `${$props.validationAlert === true ? 'bg-red-300':''}`])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.labelBack), 1)
                  ]),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    ($props.type == 'text-name')
      ? (_openBlock(), _createBlock(_component_InputGroup, {
          key: 1,
          class: _normalizeClass(["h-2rem mt-1 mb-1", `${$props.validationAlert===true ? 'border-solid border-red-900 border-round':''}`])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroupAddon, {
              class: _normalizeClass(["w-4 font-normal text-base text-700 justify-content-start", $props.backgroundText])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.labelFront), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_InputText, {
              class: _normalizeClass(["justify-content-end", `w-4`]),
              inputStyle: "`${validationAlert ? 'background: red;' : 'background: #b8d6f0;'`  text-align: right",
              name: $props.name,
              id: $props.id,
              placeholder: $props.placeholder,
              "required:string": $props.required,
              modelValue: this.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.value) = $event)),
              autocomplete: $props.name + '-new'
            }, null, 8, ["name", "id", "placeholder", "required:string", "modelValue", "autocomplete"])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    ($props.type == 'select-opening')
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 2,
          modelValue: this.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.value) = $event)),
          options: $props.option,
          optionLabel: "name",
          optionValue: "code",
          placeholder: this.value,
          class: "mb-5",
          inputStyle: "padding-left: 0.3rem; padding-top: 0.1rem"
        }, null, 8, ["modelValue", "options", "placeholder"]))
      : _createCommentVNode("", true),
    ($props.type == 'select-select')
      ? (_openBlock(), _createBlock(_component_InputGroup, {
          key: 3,
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputGroupAddon, { class: "w-6.9rem font-normal text-base text-700 justify-content-start" }, {
              default: _withCtx(() => [
                _createTextVNode("Druh skiel")
              ]),
              _: 1
            }),
            _createVNode(_component_InputGroupAddon, { class: "w-4rem font-normal text-sm text-700 pl-1 pr-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.labelFront), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Dropdown, {
              modelValue: this.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.value) = $event)),
              options: $props.option,
              optionLabel: "name",
              optionValue: "code",
              placeholder: this.value,
              class: "w-4rem",
              inputStyle: "padding-left: 0.3rem; padding-top: 0.1rem"
            }, null, 8, ["modelValue", "options", "placeholder"]),
            _createVNode(_component_InputGroupAddon, { class: "w-3.9rem font-normal text-sm text-700 pl-1 pr-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.labelBack), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Dropdown, {
              modelValue: this.value2,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.value2) = $event)),
              options: this.option2,
              optionLabel: "name",
              optionValue: "code",
              placeholder: this.value2,
              class: "w-7rem",
              inputStyle: "padding-left: 0.3rem; padding-top: 0.1rem"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.type == 'wall-comment')
      ? (_openBlock(), _createBlock(_component_InputGroup, {
          key: 4,
          class: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TextArea, {
              autoResize: "",
              rows: "2",
              class: "w-full",
              placeholder: "Poznámka",
              modelValue: this.value,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.value) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.type == 'checkbox')
      ? (_openBlock(), _createBlock(_component_InputGroup, {
          key: 5,
          class: "w-full flex align-items-center mt-2 justify-content-start"
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", _hoisted_1, _toDisplayString($props.labelFront), 1),
            _createVNode(_component_Checkbox, {
              class: "",
              modelValue: this.value,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.value) = $event)),
              binary: true,
              inputId: "holes1"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.type == 'checkbox-number')
      ? (_openBlock(), _createBlock(_component_InputGroup, {
          key: 6,
          class: "w-full flex flex-row flex-wrap align-items-center mt-2 justify-content-start"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", _hoisted_3, _toDisplayString($props.labelFront), 1),
              _createVNode(_component_Checkbox, {
                class: "",
                modelValue: this.value,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.value) = $event)),
                binary: true,
                inputId: "fix"
              }, null, 8, ["modelValue"])
            ]),
            (this.value == true)
              ? (_openBlock(), _createBlock(_component_InputGroup, {
                  key: 0,
                  class: "w-8 flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputGroupAddon, { class: "w-7 font-normal text-base text-700 justify-content-start" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.label), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_InputNumber, {
                      class: "w-4 justify-content-end",
                      inputStyle: "background: #b8d6f0; text-align: right",
                      inputId: "integeronly",
                      name: $props.name,
                      id: $props.id,
                      placeholder: $props.placeholder,
                      "required:string": $props.required,
                      modelValue: this.value2,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.value2) = $event)),
                      autocomplete: $props.name + '-new'
                    }, null, 8, ["name", "id", "placeholder", "required:string", "modelValue", "autocomplete"]),
                    ($props.labelBack !== '[-]')
                      ? (_openBlock(), _createBlock(_component_InputGroupAddon, {
                          key: 0,
                          class: "font-normal text-sm w-3"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($props.labelBack), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}