import {store} from './store'
import router from './../router/index'

const Security:any = {
    // make sure user is authenticated
    requireToken: function() {
        if (store.token === '' ) {
            router.push("/login");
            return false
        }
    },

    // create request options and send them back
    requestOptions: function(method:string, payload:any) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + store.token);
        // pripravime nastavenia pre request
        return {
            method: method,
            body: JSON.stringify(payload),
            headers: headers,
        }
    },

    // check token
    checkToken: function() {
        if (store.token !== "") {
            const payload = {
                token: store.token,
            }

            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const requestOptions = {
                method: "POST",
                body: JSON.stringify(payload),
                headers: headers,
            }

            fetch(process.env.VUE_APP_API_URL + "/validate-token", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    if (!data.data) {
                        store.token = "";
                        store.user = {
                            id: 0,
                            firstName: "",
                            lastName: "",
                            email: "",
                            admin: false,
                        },
                        document.cookie = '_site_data=; Path=/; '
                            + 'SameSite=strict; Secure; '
                            + 'Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                    }
                }
            })
        }
    }
}

export default Security;