<template>
    <div class="border-round-lg border-1"
        :class="`${typeWall === position.FRONT ? 'border-pink-500' : typeWall === position.LEFT ? 'border-cyan-500' : 'border-indigo-500'}`">
      <div class="surface-border border-round-top-lg justify-content-center"
           :class="`${typeWall === position.FRONT ? 'bg-pink-300' : typeWall === position.LEFT ? 'bg-cyan-300' : 'bg-indigo-300'}`">
         <h5 class="text-center uppercase">{{nameTypeWall}}</h5>
      </div>
      <div class="flex flex-row flex-wrap p-2">
        <div class="h-3rem surface-border border-0 justify-content-center w-full">
            <h5 class="text-center uppercase">Otváranie
              <row-input  name="opening"
                          id="opening"
                          labelFront="Otváranie"
                          labelBack=""
                          :defaultValue="this.opening"
                          type="select-opening"
                          :option="this.store.opening_types"
                          ref="glassTemperedThickness"
                          @new-wall-value="ChangeInput">
              </row-input>
            </h5>
        </div>
        <InputGroup class="h-2rem">
            <InputGroupAddon class="w-7 uppercase font-medium text-xs text-700 surface-400">Názov</InputGroupAddon>
            <InputGroupAddon class="w-3 uppercase font-medium text-xs text-700 surface-400">Zadaj</InputGroupAddon>
            <InputGroupAddon class="w-2 uppercase font-medium text-xs text-700 surface-400">Jednotky</InputGroupAddon>
        </InputGroup>
        <row-input name="width" 
                   id="width"
                   required="true"
                   labelFront="Šírka balkóna/Lodžie" 
                   labelBack="[mm]"
                   :defaultValue="this.wall.width"
                   type="number"
                   ref="width"
                   @new-wall-value="ChangeInput" 
                   >
        </row-input>

        <row-input  name="height" 
                    id="height"
                    required="true"
                    labelFront="Výša balkóna/Lodžie" 
                    labelBack="[mm]"
                    :defaultValue="this.wall.height"
                    type="number"
                    ref="height"
                    :validationAlert="!this.validationOK"
                    @new-wall-value="ChangeInput">
        </row-input>
        <row-input   v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
                     name="framesCount"
                    id="framesCount"
                    required="true"
                    labelFront="Počet krídel skiel" 
                    labelBack="[ks]"
                    :defaultValue="this.wall.framesCount"
                    type="number"
                    ref="framesCount"
                    @new-wall-value="ChangeInput">
        </row-input>

        <row-input
            v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
            name="openingDirection"
            id="openingDirection"
            labelFront="Otváranie skiel"
            labelBack="[-]"
            :defaultValue="this.wall.openingDirection"
            type="select"
            :option="this.store.opening_direction"
            ref="openingDirection"
            @new-wall-value="ChangeInput">
        </row-input>

        <div class="formgroup-inline w-full"
             v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
          <row-input name="glassTemperedThickness"
                     id="glassTemperedThickness"
                     labelFront="Hrúbka kalených skiel"
                     labelBack="[mm]"
                     :defaultValue="this.wall.glassTemperedThickness"
                     type="select"
                     :option="this.store.glass_tempered_thickness"
                     ref="glassTemperedThickness"
                     @new-wall-value="ChangeInput">
          </row-input>
          <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">(výška balkóna do 1.7m
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem "> 6
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>
          </div>
          <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">(výška balkóna od 1.7m do 2.2m
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem"> 8
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>
          </div>
          <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">(výška balkóna nad 2.2m
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem">10
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>
          </div>
        </div>

        <div v-else  class="formgroup-inline w-full">
          <row-input name="glassConnexThickness"
                     id="glassConnexThickness"
                     labelFront="Hrúbka CONNEX skiel"
                     labelBack="[mm]"
                     :defaultValue="this.wall.glassConnexThickness"
                     type="select"
                     :option="this.store.connex_thickness"
                     ref="glassConnexThickness"
                     @new-wall-value="ChangeInput">
          </row-input>
          <row-input name="glassConnexType"
                     id="glassConnexType"
                     labelFront="Typ skla"
                     labelBack="[mm]"
                     :defaultValue="this.wall.glassConnexType"
                     type="select"
                     :option="this.store.fix_glass_types"
                     ref="glassTemperedThickness"
                     @new-wall-value="ChangeInput">
          </row-input>
        </div>

        <row-input  v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
                    name="caseType"
                    id="caseType"
                    labelFront="Výška púzdra"
                    labelBack="[mm]"
                    :defaultValue="this.wall.caseType"
                    type="select"
                    :option="this.store.case_types"
                    ref="caseType"
                    @new-wall-value="ChangeInput">
        </row-input>

        <row-input  v-if="(this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING)
            && this.wall.caseType == Models.CaseType.NONE"
                    name="caseThickness"
                    id="caseThickness"
                    labelFront="Odčítanie púzdra na sklo"
                    labelBack="[mm]"
                    :defaultValue="this.wall.caseThickness"
                    type="select"
                    :option="this.store.case_type_range_0"
                    ref="caseThickness"
                    @new-wall-value="ChangeInput">
        </row-input>
        <row-input  v-if="(this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING)
            && this.wall.caseType == Models.CaseType.CASE60"
                    name="caseThickness"
                    id="caseThickness"
                    labelFront="Odčítanie púzdra na sklo"
                    labelBack="[mm]"
                    :defaultValue="this.wall.caseThickness"
                    type="select"
                    :option="this.store.case_type_range_60"
                    ref="caseThickness"
                    @new-wall-value="ChangeInput">
        </row-input>
        <row-input  v-if="(this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING)
            && this.wall.caseType == Models.CaseType.CASE90"
                    name="caseThickness"
                    id="caseThickness"
                    labelFront="Odčítanie púzdra na sklo"
                    labelBack="[mm]"
                    :defaultValue="this.wall.caseThickness"
                    type="select"
                    :option="this.store.case_type_range_90"
                    ref="caseThickness"
                    @new-wall-value="ChangeInput">
        </row-input>
        <row-input v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
                   name="anotherProfilesThickness"
                   id="anotherProfilesThickness"
                   labelFront="Odčítanie iných profilov"
                   labelBack="[mm]"
                   :defaultValue="this.wall.anotherProfilesThickness"
                   type="number"
                   ref="anotherProfilesThickness"
                   @new-wall-value="ChangeInput">
        </row-input>
        <div class="formgroup-inline pl-2 w-full" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <div class="text-xs font-light text-500 w-11rem">(profily s plastovými kolieskami
            </div>
            <div class="text-xs font-light text-500 ml-2 w-3rem ">170
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>                        
        </div>
        <div class="formgroup-inline pl-2 w-full" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <div class="text-xs font-light text-500 w-11rem">(profily s oceľovými kolieskami
            </div>
            <div class="text-xs font-light text-500 ml-2 w-3rem">175, 177
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>                        
        </div>
    <!-- <div class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-11rem">
            </div>
            <div class="text-xs font-light text-500 ml-2 w-1rem">177
            </div>
            <div class="text-xs font-light text-500 ml-1">mm)
            </div>                        
        </div>  
    -->                   
        <row-input  v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
                    name="capType"
                    id="capType"
                    labelFront="Typ krytiek" 
                    labelBack="[mm]"
                    :defaultValue="this.wall.capType"
                    type="select"
                    :option="this.store.cap_type"
                    ref="capType"
                    @new-wall-value="ChangeInput">
        </row-input>     
        <div v-if="this.wall.opening != Opening.FIXED" class="formgroup-inline pl-2 w-full">
            <div class="text-xs font-light text-500 w-5rem">(čierne krytky
            </div>
            <div class="text-xs font-light text-500 ml-0 w-1rem">15
            </div>
            <div class="text-xs font-light text-500 ml-0">mm,
            </div> 
            <div class="text-xs font-light text-500 w-5rem ml-2">sivé krytky
            </div>
            <div class="text-xs font-light text-500 ml-0 w-1rem">16
            </div>
            <div class="text-xs font-light text-500 ml-0">mm)
            </div>                                              
        </div>

        <row-input  name="profilesColor"
                    id="profilesColor"
                    labelFront="Farba profilov" 
                    labelBack="[-]"
                    :defaultValue="this.wall.profilesColor"
                    type="text-blank"
                    ref="profilesColor"
                    @new-wall-value="ChangeInput">
        </row-input>    
        <row-input  v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
                    name="glazingType"
                    name2="glazingTypeAttribute" 
                    id="glazingType"
                    labelFront="Stopsol" 
                    labelBack="farba"
                    :defaultValue="this.wall.glazingType"
                    :defaultValue2="this.wall.glazingTypeAttribute"                    
                    type="select-select"
                    :option="this.store.glazing_type"
                    :option2="this.store.glazing_type_attribute"                    
                    ref="glazingType"
                    @new-wall-value="ChangeInput">
        </row-input>

        <row-input  v-if="this.wall.opening == Opening.COMBINED"
                    name="fixLeft"
                    name2="fixLeftWidth" 
                    id="fixLeft"
                    labelFront="Fix vľavo" 
                    label="Šírka fixu"
                    labelBack="[mm]"
                    :defaultValue="this.wall.fixLeft"
                    :defaultValue2="this.wall.fixLeftWidth"                        
                    type="checkbox-number"
                    ref="fixLeft"
                    @new-wall-value="ChangeInput">
        </row-input> 
        <row-input  v-if="this.wall.opening == Opening.COMBINED"
                    name="fixRight"
                    name2="fixRightWidth" 
                    id="fixRight"
                    labelFront="Fix vpravo" 
                    label="Šírka fixu"
                    labelBack="[mm]"
                    :defaultValue="this.wall.fixRight"
                    :defaultValue2="this.wall.fixRightWidth"                        
                    type="checkbox-number"
                    ref="fixRight"
                    @new-wall-value="ChangeInput">
        </row-input>

        <input v-if="this.typeCode == TypeCode.B_F_H"
               type="hidden" name="holes" id="holes" value="true">

        <row-input  v-if="this.wall.opening == Opening.FIXED"
                    name="fixedVerticalSplits"
                    id="fixedVerticalSplits"
                    required="false"
                    labelFront="Počet vertikálnych členení"
                    labelBack="[ks]"
                    :defaultValue="this.wall.fixedVerticalSplits"
                    type="number"
                    ref="fixedVerticalSplits"
                    @new-wall-value="ChangeInput">
        </row-input>
        <row-input  v-if="this.wall.opening == Opening.FIXED"
                    name="fixedHorizontalSplits"
                    id="fixedHorizontalSplits"
                    required="false"
                    labelFront="Počet horizontálnych členení"
                    labelBack="[ks]"
                    :defaultValue="this.wall.fixedHorizontalSplits"
                    type="number"
                    ref="fixedHorizontalSplits"
                    @new-wall-value="ChangeInput">
        </row-input>

        <InputGroup class="h-2rem mt-2">
            <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-gray-300">Poznámky</InputGroupAddon>
        </InputGroup>   

        <InputGroup>

          <TextArea autoResize rows="2" class="w-full"
                placeholder="Poznámka ku stene"
                v-model="this.wall.comment"
                style="background: #b8d6f0; text-align: left"
          />
        </InputGroup>

        <InputGroup class="h-2rem mt-3">
            <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-300">Výstupné údaje</InputGroupAddon>
        </InputGroup> 
        <InputGroup class="h-3rem" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Predbežný výpočet šírky všetkých profilov</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.profilesLength}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka profilu</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.singleProfileLength}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED && this.wall.fixLeftWidth > 0">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka profilu FIXU ľavý</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.fixLeftProfileWidth}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED && this.wall.fixRightWidth > 0">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka profilu FIXU pravý</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.fixRightProfileWidth}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <row-input  v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING"
                    name="manualSingleProfileLength"
                    id="manualSingleProfileLength"
                    labelFront="Zadaná šírka profilu" 
                    labelBack="[mm]"
                    :defaultValue="this.wall.manualSingleProfileLength"
                    type="number"
                    ref="manualSingleProfileLength"
                    backgroundText="bg-gray-300"
                    @new-wall-value="ChangeInput">
        </row-input>         
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start bg-gray-300">
              Prepočtová šírka
              <span v-if="this.getTotalLengthDiff() > 0" style="color: var(--red-500)">
              &nbsp;-{{this.getTotalLengthDiff()}}mm!
              </span>
            </InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">
            <span :style="this.getTotalLengthDiff() > 0 ? 'color: var(--red-500)':''">
              {{this.wall.totalProfilesLength}}
              </span>
            </InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup> 
        <InputGroup class="h-2rem" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-full uppercase font-medium text-sm text-800 bg-red-100">Výpočet skutočnej šírky skla</InputGroupAddon>
        </InputGroup>  
        <InputGroup class="h-2rem" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Krajné sklo</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.sideGlassWidth}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup> 
        <InputGroup class="h-2rem " v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Počet krajných skiel</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.sideGlassCount}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[ks]</InputGroupAddon>
        </InputGroup>   
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Stredové sklo</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.middleGlassWidth}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup> 
        <InputGroup class="h-2rem " v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Počet stredových skiel</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.middleGlassCount}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[ks]</InputGroupAddon>
        </InputGroup>   
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED || this.wall.opening == Opening.SLIDING">
            <InputGroupAddon class="w-7  font-medium text-base text-800 justify-content-start bg-red-100">Výpočet výšky skiel</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.computedGlassHeight}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>  
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.COMBINED">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka posuvu</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.centerRailingWidth}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.FIXED">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Šírka skla</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.fixedGlassWidth}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="h-2rem mt-2" v-if="this.wall.opening == Opening.FIXED">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Výška skla</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.wall.fixedGlassHeight}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
      </div>
    </div>
</template>

<script lang="ts">

import Security from '../security';
//import notie from 'notie/dist/notie'
import {store} from '../store';
import RowInput from "@/components/forms/RowInput.vue";
import {BksGlass, Models, WallType} from '../models';
import TextArea from "primevue/textarea";
import CaseType = Models.CaseType;
import Opening = Models.Opening;
import TypeCode = Models.TypeCode;

//import {emiter} from '../../main';
const BksGlasses: BksGlass[] = [];
const wall : WallType = {
  id: 0,
  opening: Models.Opening.SLIDING,
  position: Models.Position.FRONT,
  width: 3500.0,
  height: 1366.0,
  framesCount: 6,
  glassTemperedThickness: "6",
  caseType: CaseType.NONE,
  caseThickness: 0.0,
  anotherProfilesThickness: 173,
  capType: 16,
  openingDirection: Models.OpeningDirection.LEFT,
  profilesColor: "",
  glazingType: "nie",
  glazingTypeAttribute: "číra",
  manualSingleProfileLength:0,
  holes: false,
  profilesLength: 0,
  singleProfileLength: 0,
  totalProfilesLength:0,
  sideGlassWidth: 0,
  sideGlassCount: 0,
  middleGlassWidth: 0,
  middleGlassCount: 0,
  computedGlassHeight: 0,
  bks: false,
  bksGlasses: BksGlasses,
  fixLeft: false,
  fixLeftWidth: 0,
  fixLeftProfileWidth: 0,
  fixLeftGlassWidth: 0,
  fixRight: false,
  fixRightWidth: 0,
  fixRightProfileWidth: 0,
  fixRightGlassWidth: 0,
  centerRailingWidth: 0,
  combined: false, // the wall main contains fixed part too
  barrier: true,
  comment: ""
}

export default {
  name: 'GlazingWall',
  computed: {
    TypeCode() {
      return TypeCode
    },
    Models() {
      return Models
    },
    Opening() {
      return Opening
    }
  },
    components:{
      TextArea,
       RowInput

    }, 
    props: {
      typeWall: String,
      typeCode: String,
      isBarrier:Boolean,
      validationOK: Boolean,
      validationAttr: String
    },
    data() {
      return {
        store,
        nameWall: String,
        nameTypeWall: String,
        wall: wall,
        opening: Models.Opening.SLIDING,
        key: 0 as Number,
        position: Models.Position,
        //holes: Boolean
      }
    },
    methods: {
        ChangeInput(data:any): void{

            switch(data.name){
            case "opening":
                this.wall.opening = data.value;
                if(this.wall.opening == Opening.SLIDING || this.wall.opening == Opening.FIXED){
                  this.wall.combined = false
                }
              break;
            case "width": this.wall.width = data.value;
            break;
            case "height":  this.wall.height = data.value;
            break;
            case "framesCount":  this.wall.framesCount = data.value;
            break;
            case "glassTemperedThickness":  this.wall.glassTemperedThickness = data.value;
            break;
            case "caseType":
              this.wall.caseType = data.value;
              // handle default value of this.wall.caseThickness
                if(!this.wall.caseThickness){
                   this.wall.caseThickness = 0.0
                }

                console.log('caseThickness before update', this.wall.caseThickness)
                console.log('caseType change val', this.wall.caseType)

                if(this.wall.caseType == CaseType.NONE && this.wall.caseThickness > 3.0){
                  this.wall.caseThickness = 0;
                  console.log('updating caseThickness 0 to ', this.wall.caseThickness )
                }else if(this.wall.caseType == CaseType.CASE60 && this.wall.caseThickness < 40.0 || this.wall.caseThickness > 50.0){
                  this.wall.caseThickness = 40.0;
                  console.log('updating caseThickness 60 to ', this.wall.caseThickness )
                }else if(this.wall.caseType == CaseType.CASE90 && this.wall.caseThickness < 50.0 || this.wall.caseThickness > 60.0){
                  this.wall.caseThickness = 70.0;
                  console.log('updating caseThickness 60 to ', this.wall.caseThickness )
                }

              console.log('updated casethicknes: ', this.wall.caseThickness)
            break;
            case "caseThickness":  this.wall.caseThickness = Number(data.value);
            break;
            case "anotherProfilesThickness":  this.wall.anotherProfilesThickness = data.value;
            break;
            case "capType":  this.wall.capType = data.value;
            break;
            case "openingDirection":  this.wall.openingDirection = data.value;
            break;
            case "profilesColor":  this.wall.profilesColor = data.value;
            break;
            case "glazingType":  this.wall.glazingType = data.value;
            break;
            case "glazingTypeAttribute":  this.wall.glazingTypeAttribute = data.value;
            break;   
            case "manualSingleProfileLength":  this.wall.manualSingleProfileLength = data.value;
            break;    
            case "holes":  this.wall.holes = data.value;
            break;       
            case "comment":  this.wall.comment = data.value;
            break;  
            case "fixLeft":  this.wall.fixLeft = data.value;
            break;   
            case "fixLeftWidth":  this.wall.fixLeftWidth = data.value;
            break;   
            case "fixRight":  this.wall.fixRight = data.value;
            break;   
            case "fixRightWidth":  this.wall.fixRightWidth = data.value;
            break;
            case "fixedGlassWidth":  this.wall.fixedGlassWidth = data.value;
            break;
            case "fixedGlassHeight":  this.wall.fixedGlassHeight = data.value;
            break;
            case "glassConnexThickness":  this.wall.glassConnexThickness = data.value;
            break;
            case "glassConnexType":  this.wall.glassConnexType = data.value;
            break;
            }
            this.store.order.walls[this.key] = this.wall
            //this.$emit('change-wall')
        },
      getTotalLengthDiff(){
          let fixDiff = 0;
          if(this.wall.fixLeft){
            fixDiff += this.wall.fixLeftWidth;
          }
        if(this.wall.fixRight){
          fixDiff += this.wall.fixRightWidth;
        }
        return this.wall.width - this.wall.totalProfilesLength - fixDiff;
      },
      openingLabel(opening:Models.Opening){
          return this.store.opening_labels[opening]
      }
        //console.log("opt:",this.glazingTypeValue);
    },
    mounted() {
        // console.log("idorder:", store.order.id)
    },
    beforeMount() {
        Security.requireToken();
        //console.log("idorder:", store.order.id)
       //this.$refs.width.focus()
       //this.$el.querySelector("#width").focus();
       switch(this.$props.typeWall){
        case this.position.FRONT: 
            this.nameTypeWall = this.store.front;
            this.nameWall = this.store.front_open;
            break;
        case this.position.LEFT:
            this.nameTypeWall = this.store.left;
            this.nameWall = this.store.side_open;
            break;
        case this.position.RIGHT:
            this.nameTypeWall = this.store.right;
            this.nameWall = this.store.side_open;
            break;            
       }

       if (this.store.order.id === 0){
          // použi defaultné data pre stenu, inak použi dáta z existujúcej obj
          if (this.$props.typeWall === this.position.LEFT || this.$props.typeWall === this.position.RIGHT){
            if(this.opening === Models.Opening.SLIDING || this.opening === Models.Opening.COMBINED){
                this.wall = {...this.store.walls_default[1]};       // bočné otváravé
                this.wall.position = this.$props.typeWall === this.position.LEFT ? this.position.LEFT : this.position.RIGHT
            }else{this.wall = {...this.store.walls_default[2]}       // bočné pevné
                  this.wall.position = this.$props.typeWall === this.position.LEFT ? this.position.LEFT : this.position.RIGHT    
                }
            }else{this.wall = {...this.store.walls_default[0]}}      // predné
            
            this.wall.barrier = this.isBarrier
            this.wall.bks = !this.isBarrier
            this.key = this.store.order.walls.push(this.wall) - 1     // pridá stenu do order
       
        }else{
          // potrebné vyhľadať stenu podľa opening a position
          let index = this.store.order.walls.findIndex(obj => obj.position === this.$props.typeWall)
          this.wall = this.store.order.walls[index]
         if (typeof this.store.order.walls[index] != "undefined"){
          this.opening = this.store.order.walls[index].opening
         }
       }

       //console.log("wall:", store.glass_tempered_thickness)
    },
    
}
</script>

<style scoped>
   .p-dropdown {
    height: 2.1rem; 
    padding: 0.2rem;
   }
   .p-inputgroup-addon {
    padding: 1rem 0.5rem;
   }
</style>