<template>
    <header class="d-flex flex-wrap justify-content-center py-2 pl-2 pr-2 border-bottom">
      <a href="/" class="d-flex align-items-center mb-md-0 me-md-auto text-dark text-decoration-none">
        <img src="/img/pifema-logo-dark.svg" width="130">
        <span class="fs-4 ml-2 " style="padding-top: 0.2rem">  kalkulačka</span>
      </a>

      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
        <!--
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        -->
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link v-if="store.token != ''" to="/" class="nav-link">
                  <PrimevueButton label="Nová obj." severity="success" text raised />
                </router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="store.token != ''" to="/orders" class="nav-link">
                  <PrimevueButton label="Objednávky" severity="info" text raised />
                </router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="store.token != '' && store.user.admin === true" to="/admin/users" class="nav-link">
                  <PrimevueButton label="Užívatelia" severity="info" text raised />
                </router-link>
              </li>
              <li class="nav-item">
                <router-link v-if="store.token == ''" to="/login" class="nav-link">
                   <PrimevueButton label=" Prihlásenie" severity="info" text raised />
                </router-link>
                <a href="javascript:void(0);" v-else  class="nav-link" @click="logout()">
                   <PrimevueButton label="Odhlásiť sa" plain text raised class=""/>
                </a> 
              </li>

            </ul>
            <div v-if="store.token != ''" class="d-flex mb-2 pl-2">
              <PrimevueButton :label="store.user.firstName ?? ''" severity="info" text raised class="font-medium text-base text-800"/>
            </div>
          </div>
        </div>
      </nav>
    </header>
</template>

<script lang="ts">
import {store} from "@/components/store";
import Security from './security';

import notie from "notie/dist/notie";
import router from "@/router";
import PrimevueButton from 'primevue/button';

export default {
  components:{
    PrimevueButton,

  },
  data() {
    return {
      store
    }
  },
  methods: {

    logout() {
      const payload = {
        token: store.token
      }

      fetch(store.Api_url+'/logout', Security.requestOptions("POST",payload))
          .then((response) => response.json)
          .then((response) => {
            if ((response as any).error) {
              //console.log((response as any).message)

            } else {

              // premaze sa token a user na frontende
              store.token = ""
              store.user = {
                    id: 0,
                    firstName: "",
                    lastName: "",
                    email: "",
                    admin: false,
              }

              // premazu sa data
              document.cookie = '_bks_data=; Path/;' +
                  ";SameSite=strict; Secure;" +
                  'Expires= Thu, 01 Jan 1970 00:00:01 GMT;'


              // vypise sa hlaska
              notie.alert({
                type: 'warning',
                text: "Úspešne ste sa odhlásili",
                position: "bottom"
              })

              // jednoduche presmerovanie po odhlaseni sa
              router.push({path: '/login'})
            }
          })
    }
  }
}
</script>
<style scoped>
  button {
    border-radius: 6px;
  }
</style>