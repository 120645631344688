import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b33e359"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-round-lg border-1 border-cyan-500" }
const _hoisted_2 = { class: "surface-border border-round-top-lg justify-content-center bg-cyan-300" }
const _hoisted_3 = { class: "text-center uppercase" }
const _hoisted_4 = { class: "p-1" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_input = _resolveComponent("row-input")!
  const _component_InputGroupAddon = _resolveComponent("InputGroupAddon")!
  const _component_InputGroup = _resolveComponent("InputGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, "Sklo #" + _toDisplayString((this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].position+1)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_row_input, {
        name: "name",
        id: "name",
        required: "true",
        labelFront: "Typ skla",
        defaultValue: this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].name,
        type: "text-name",
        ref: "name",
        inputStyle: "background: #b8d6f0; text-align: left",
        onNewWallValue: $options.changeInput
      }, null, 8, ["defaultValue", "onNewWallValue"]),
      _createVNode(_component_InputGroup, { class: "mt-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroupAddon, { class: "w-4 font-normal text-base text-700 justify-content-start" }, {
            default: _withCtx(() => [
              _createTextVNode("Šírka")
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroupAddon, { class: "w-6 font-normal text-base text-700" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].width), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
            default: _withCtx(() => [
              _createTextVNode("[mm]")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_InputGroup, { class: "mt-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroupAddon, { class: "w-4 font-normal text-base text-700 justify-content-start" }, {
            default: _withCtx(() => [
              (this.store.order.fixes[this.$props.fixKey].atypical)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Výška A"))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, "Výška"))
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroupAddon, { class: "w-6 font-normal text-base text-700" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].heightA), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
            default: _withCtx(() => [
              _createTextVNode("[mm]")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (this.store.order.fixes[this.$props.fixKey].atypical)
        ? (_openBlock(), _createBlock(_component_InputGroup, {
            key: 0,
            class: "mt-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputGroupAddon, { class: "w-4 font-normal text-base text-700 justify-content-start" }, {
                default: _withCtx(() => [
                  _createTextVNode("Výška B")
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroupAddon, { class: "w-6 font-normal text-base text-700" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.store.order.fixes[this.$props.fixKey].fixGlasses[this.fixGlassKey].heightB), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
                default: _withCtx(() => [
                  _createTextVNode("[mm]")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}