import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
//import mitt from "mitt"

import PrimeVue from 'primevue/config';
import '/node_modules/primeflex/primeflex.css'
import 'primevue/resources/themes/md-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'; // Core styles
import 'primeicons/primeicons.css'; // Icons
//import Button from "primevue/button"
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
//import Card from 'primevue/card';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import DataTable from "primevue/datatable";
import DataColumn from "primevue/column";


//export const emiter = mitt()
const app = createApp(App);
app.use(router)
app.use(PrimeVue);
//app.component('ButtonNext', Button);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('DataTable', DataTable)
app.component('DataColumn', DataColumn)
//app.component('DropDown', Dropdown);
app.mount('#app')