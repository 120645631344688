import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-755f758e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-round-lg border-1 border-pink-500" }
const _hoisted_2 = { class: "surface-border border-round-top-lg justify-content-center bg-pink-300" }
const _hoisted_3 = { class: "text-center uppercase" }
const _hoisted_4 = { class: "p-1" }
const _hoisted_5 = {
  key: 1,
  for: "leftOffsetLock_0",
  class: "ml-2"
}
const _hoisted_6 = {
  key: 4,
  for: "rightOffsetLock_last",
  class: "ml-2"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroupAddon = _resolveComponent("InputGroupAddon")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, "Priečnik #" + _toDisplayString((this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].position + 1)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_InputGroup, { class: "mt-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroupAddon, { class: "w-7 font-normal text-base text-700 justify-content-start" }, {
            default: _withCtx(() => [
              _createTextVNode("Vzdialenosť z ľava ")
            ]),
            _: 1
          }),
          _createVNode(_component_InputNumber, {
            class: "w-3 justify-content-end",
            inputStyle: "background: #b8d6f0; text-align: right; height: 2.1rem",
            name: "leftOffset",
            id: "leftOffset",
            required: "required",
            modelValue: this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].leftOffset,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].leftOffset) = $event)),
            autocomplete: `leftOffset-new`,
            onInput: $options.changeInput
          }, null, 8, ["modelValue", "onInput"]),
          _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
            default: _withCtx(() => [
              _createTextVNode("[mm]")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (this.fixBinderKey == 0)
        ? (_openBlock(), _createBlock(_component_InputSwitch, {
            key: 0,
            id: "leftOffsetLock_0",
            modelValue: this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].lockLeftOffset,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].lockLeftOffset) = $event)),
            class: "mt-2 offset-lock offset-lock-left",
            onInput: $options.changeInput
          }, null, 8, ["modelValue", "onInput"]))
        : _createCommentVNode("", true),
      (this.fixBinderKey == 0)
        ? (_openBlock(), _createElementBlock("label", _hoisted_5, "Zamknúť zľava"))
        : _createCommentVNode("", true),
      (this.fixBinderKey == (this.store.order.fixes[this.$props.fixKey].fixBinders.length - 1))
        ? (_openBlock(), _createBlock(_component_InputGroup, {
            key: 2,
            class: "mt-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputGroupAddon, { class: "w-7 font-normal text-base text-700 justify-content-start" }, {
                default: _withCtx(() => [
                  _createTextVNode("Vzdialenosť z prava ")
                ]),
                _: 1
              }),
              _createVNode(_component_InputNumber, {
                class: "w-3 justify-content-end",
                inputStyle: "background: #b8d6f0; text-align: right; height: 2.1rem",
                name: "rightOffset",
                id: "rightOffset",
                required: "required",
                modelValue: this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].rightOffset,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].rightOffset) = $event)),
                autocomplete: `rightOffset-new`,
                onInput: $options.changeInput
              }, null, 8, ["modelValue", "onInput"]),
              _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
                default: _withCtx(() => [
                  _createTextVNode("[mm]")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (this.fixBinderKey == (this.store.order.fixes[this.$props.fixKey].fixBinders.length - 1))
        ? (_openBlock(), _createBlock(_component_InputSwitch, {
            key: 3,
            id: "rightOffsetLock_last",
            modelValue: this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].lockRightOffset,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].lockRightOffset) = $event)),
            class: "mt-2 offset-lock offset-lock-right",
            onInput: $options.changeInput
          }, null, 8, ["modelValue", "onInput"]))
        : _createCommentVNode("", true),
      (this.fixBinderKey == (this.store.order.fixes[this.$props.fixKey].fixBinders.length - 1))
        ? (_openBlock(), _createElementBlock("label", _hoisted_6, "Zamknúť zprava"))
        : _createCommentVNode("", true),
      _createVNode(_component_InputGroup, { class: "mt-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroupAddon, { class: "w-7 font-normal text-base text-700 justify-content-start" }, {
            default: _withCtx(() => [
              (this.store.order.fixes[this.$props.fixKey].atypical)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Výška A"))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "Výška"))
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroupAddon, { class: "w-3 font-normal text-base text-700" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].heightA), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
            default: _withCtx(() => [
              _createTextVNode("[mm]")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (this.store.order.fixes[this.$props.fixKey].atypical)
        ? (_openBlock(), _createBlock(_component_InputGroup, {
            key: 5,
            class: "mt-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputGroupAddon, { class: "w-7 font-normal text-base text-700 justify-content-start" }, {
                default: _withCtx(() => [
                  _createTextVNode("Výška B")
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroupAddon, { class: "w-3 font-normal text-base text-700" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].heightB), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_InputGroupAddon, { class: "w-2 font-normal text-sm" }, {
                default: _withCtx(() => [
                  _createTextVNode("[mm]")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}