<script lang="ts">
import Security from "@/components/security";
import {store} from "@/components/store";
import router from "@/router";
import notie from "notie/dist/notie";

export default {
  name: "UserList",
  data() {
    return {
      store,
      users: [],
    }
  },
  beforeMount() {
    console.log("calling users")
    Security.requireToken();

    if(store.user.admin===false){
      router.push("/")
      notie.alert({
        type: 'error',
        text: "Na túto akciu nemáte oprávnenia"
      })
    }

    fetch(store.Api_url + "/v1/user?query=active:1", Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            this.$emit('error', responseData.data);
            notie.alert({
              type: 'error',
              text: "Na túto akciu nemáte oprávnenia"
            })
          } else {
            console.log(responseData);
            this.users = responseData.data
          }
        })
        .catch((error) => {
          this.$emit('error', "Chyba pri načítaní užívateľov" + error);
        })
  }
}
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col md-7">
        <h1 class="mt-3">Zoznam užívateľov</h1>
        <router-link to="/admin/users/0" class="btn btn-primary float-end">Pridať užívateľa</router-link>


        <table class="table table-compact table-striped hover">
          <thead>
          <tr>
            <th>Užívateľ</th>
            <th>Spoločnosť</th>
            <th>Email</th>
            <th>Tel</th>
            <th>Admin</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="u in this.users" v-bind:key="u.id">
            <td>
              <router-link :to="`/admin/users/${u.id}`">{{u.lastName}} {{u.firstName}}</router-link>
            </td>
            <td>{{u.company}}</td>
            <td>{{u.email}}</td>
            <td>{{u.phone}}</td>
            <td>
              <span v-if="u.admin === true">Správca</span>
            </td>
            <td>
              <router-link :to="`/admin/users/${u.id}`" class="btn btn-primary">Upraviť</router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>



    </div>
  </div>
</template>

<style scoped>

</style>