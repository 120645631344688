// store.js
import {reactive} from 'vue'
import {BksGlass, Models, OrderType, WallType, FixType, ProfileType,FixBinder,FixGlass, OptionType} from './models'
import CaseType = Models.CaseType;

const User: Models.UserType = {
    id: 0, // Default values, replace with actual values as needed
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    email: "",
    admin: false
};

const Type: Models.LayoutType = {
    id: 1, // Default values, replace with actual values as needed
    name: "Čelné zasklenie",
    code: Models.TypeCode.B_F,
    thumbnail: ""
};

const CurrentState: Models.OrderState = {
    id: 1,
    reference:  Models.State.NEW,
    label: "", 
   
};

const Types: Models.LayoutType[] = [];
const Walls: WallType[] = [];
const BksGlasses: BksGlass[] = [];
const WallsEmpty: WallType[] = [];
const Fixes: FixType[] = [];
const FixesEmpty: FixType[] = [];
const Profiles: ProfileType[] = [];
const FixBinders: FixBinder[] = [];
const FixGlasses: FixGlass[] = [];
const OrderHistory: Models.OrderHistory[] = [];
const OrderStates: Models.OrderState[] = [];
const EmailHistories: Models.EmailHistory[] = [];
const EmailRecipients: Models.EmailRecipient[] = [];
const EmailRecipientOptions: any[] = [];

const anotherProfileThickness = 170

const WallsDefault: WallType[] = [
    {
        id: 0,
        opening: Models.Opening.SLIDING,
        position: Models.Position.FRONT,
        width: 0.0,
        height: 0.0,
        framesCount: 0,
        glassTemperedThickness: "6",
        glassConnexThickness: "3-1-3",
        caseType: CaseType.NONE,
        caseThickness: 3,
        anotherProfilesThickness: anotherProfileThickness,
        capType: 16,
        openingDirection: Models.OpeningDirection.LEFT,
        profilesColor: "",
        glazingType: "nie",
        glazingTypeAttribute: "číra",
        manualSingleProfileLength:0,
        holes: false,
        handle: false,
        customDoor: false,
        customDoorProfileWidth: 0,
        doorPosition: "",
        profilesLength: 0,
        singleProfileLength: 0,
        totalProfilesLength:0,
        sideGlassWidth: 0,
        sideGlassCount: 0,
        middleGlassWidth: 0,
        middleGlassCount: 0,
        computedGlassHeight: 0,
        bks: false,
        bksGlasses: BksGlasses,
        
        fixLeft: false,
        fixLeftWidth: 0,
        fixLeftProfileWidth: 0,
        fixLeftGlassWidth: 0,
    
        fixRight: false,
        fixRightWidth: 0,
        fixRightProfileWidth: 0,
        fixRightGlassWidth: 0,
    
        centerRailingWidth: 0,

        combined: false, // the wall main contains fixed part too
        barrier: true,
        comment: ""
    } as const,
    {
        id: 0,
        opening: Models.Opening.SLIDING,
        position: Models.Position.LEFT,
        width: 0.0,
        height: 0.0,
        framesCount: 0,
        glassTemperedThickness: "6",
        caseType: CaseType.NONE,
        caseThickness: 0,
        anotherProfilesThickness: 170,
        capType: 16,
        openingDirection: Models.OpeningDirection.RIGHT,
        profilesColor: "",
        glazingType: "nie",
        glazingTypeAttribute: "číra",
        manualSingleProfileLength:0,
        holes: false,
        glassConnexType: "",
        glassConnexThickness: "",
        fixedHorizontalSplits: 0,
        fixedVerticalSplits: 0,
        profilesLength: 0,
        singleProfileLength: 0,
        totalProfilesLength:0,
        sideGlassWidth: 0,
        sideGlassCount: 0,
        middleGlassWidth: 0,
        middleGlassCount: 0,
        computedGlassHeight: 0,
        bks: false,
        bksGlasses: BksGlasses,

        fixLeft: false,
        fixLeftWidth: 0,
        fixLeftProfileWidth: 0,
        fixLeftGlassWidth: 0,
    
        fixRight: false,
        fixRightWidth: 0,
        fixRightProfileWidth: 0,
        fixRightGlassWidth: 0,
    
        centerRailingWidth: 0,

        combined: false, // the wall main contains fixed part too
        barrier: true,
        comment: ""
    } as const,
    {
        id: 0,
        opening: Models.Opening.FIXED,
        position: Models.Position.LEFT,
        width: 0.0,
        height: 0.0,
        framesCount: 0,
        glassTemperedThickness: "6",
        caseType: CaseType.NONE,
        caseThickness: 3,
        anotherProfilesThickness: 170,
        capType: 16,
        openingDirection: Models.OpeningDirection.LEFT,
        profilesColor: "",
        glazingType: "nie",
        glazingTypeAttribute: "číra",
        manualSingleProfileLength:0,
        holes: false,
        glassConnexType: "",
        glassConnexThickness: "",
        fixedHorizontalSplits: 0,
        fixedVerticalSplits: 0,
        profilesLength: 0,
        singleProfileLength: 0,
        totalProfilesLength:0,
        sideGlassWidth: 0,
        sideGlassCount: 0,
        middleGlassWidth: 0,
        middleGlassCount: 0,
        computedGlassHeight: 0,
        bks: false,
        bksGlasses: BksGlasses,

        fixLeft: false,
        fixLeftWidth: 0,
        fixLeftProfileWidth: 0,
        fixLeftGlassWidth: 0,
    
        fixRight: false,
        fixRightWidth: 0,
        fixRightProfileWidth: 0,
        fixRightGlassWidth: 0,
    
        centerRailingWidth: 0,

        combined: false, // the wall main contains fixed part too
        barrier: true,
        comment: ""        
    } as const,

];
//user: {...User},
//currentState: {...CurrentState} ,
const Order: OrderType = {
    id: 0,
    user: {...User},
    modifiedUser: {...User},
    currentState: {...CurrentState} ,
    orderNumber: 0,
    customerName: "",
    address: "",
    floor: 0,
    phone: "",
    email: "",
    installationDate: new Date(), // Default value, replace with actual value as needed
    type: {...Type}, // Reference to the Type object
    roof: Models.Roof.NONE,
    roofDimA: 0,
    roofDimB: 0,
    parapet: false,
    parapetDimA: 0,
    parapetDimB: 0,
    railingReplace: false,
    railingComment:"",
    railingInfill: Models.RailingInfill.NONE,
    consoles: 0,
    comment: "",
    additions: "",    
    walls: Walls,
    glassesComment: "",
    orderNumYear: 0,
    orderNumCode: 0,
    fixes: Fixes,
    modifiedDate: new Date(),

};

const OrderDefault: OrderType = {
    id: 0,
    user: {...User},
    currentState: {...CurrentState} ,   
    orderNumber: 0,
    customerName: "",
    address: "",
    floor: 0,
    phone: "",
    email: "",
    installationDate: new Date(), // Default value, replace with actual value as needed
    type: {...Type}, // Reference to the Type object
    roof: Models.Roof.NONE,
    roofDimA: 0,
    roofDimB: 0,
    parapet: false,
    parapetDimA: 0,
    parapetDimB: 0,
    railingReplace: false,
    railingComment:"",
    railingInfill: Models.RailingInfill.NONE,
    consoles: 0,
    comment: "",
    additions: "",    
    walls: WallsEmpty,
    glassesComment: "",
    orderNumYear: 0,
    orderNumCode: 0,
    fixes: FixesEmpty,

} as const;

const Profile: ProfileType = {
    id:   0,
    name: "",
	glassReduction: 0,
    cutReduction: 0,
    thumbnail: "",
    thumbnail_dim: "",
    width: 0,
    binder: false,
    active: true, 
}

const ProfileDefault: ProfileType = {
    id:   1,
    name: "2022",
	glassReduction: 12,
    cutReduction: 20,
    thumbnail: "assets/cortizo/2022.jpg",
    thumbnail_dim: "",
    width: 47,
    binder: false,
    active: true, 
} as const

const BinderProfileDefault: ProfileType = {
    id:   2,
    name: "2033",
	glassReduction: 12,
    cutReduction: 20,
    thumbnail: "assets/cortizo/2033.jpg",
    thumbnail_dim: "",
    width: 67,
    binder: true,
    active: true, 
} as const

const Fix: FixType = {
    id: 0,
    order : {...Order},
    profile: {...Profile},
    glassType: "",
    width:     0,
    atypical: false,
    heightA:   0,
    heightB:   0,
    name:      "",
    desc:      "",
    area:      0,
    price:     0,
    active:    true,
    binderProfile: {...Profile},
    binderCount: 0,
    fixBinders:  FixBinders,
    fixGlasses:  FixGlasses,
    deletedDate: new Date(),
    angle: 0,
};

const FixDefault: FixType = {
    id:        0,
    order :    {...OrderDefault},
    profile:   {...ProfileDefault},
    glassType: "",
    width:     0,
    heightA:   0,
    heightB:   0,
    name:      "",
    desc:      "",
    area:      0,
    price:     0,
    active:    true,
    binderProfile: {...BinderProfileDefault},
    binderCount: 0,
    fixBinders:  FixBinders,
    fixGlasses:  FixGlasses, 
    angle: 0,   
} as const;

const EmailHistory: Models.EmailHistory = {
    id: 0,
    order:  {...Order},
    whom: "",
    subject: "",
    createDate: new Date(),
    user: {...User},
}

const EmailRecipient: Models.EmailRecipient = {
    id: 0,
    name: "",
    email: "",
    glassType: "",
}    

const EmailRecipientOption: any = {
    id: 0,
    name: "",

}    

const API_URL = process.env.VUE_APP_API_URL;
const FRONT_OPEN = "Čelné zasklenie otváravé" as const
const SIDE_OPEN = "Bočné zasklenie otváravé" as const
const SIDE_FIXED = "Bočné zasklenie pevné" as const
const FRONT = "Čelné zasklenie" as const
const LEFT = "Ľavé zasklenie" as const
const RIGHT = "Pravé zasklenie" as const
const BARRIER = "Bariérový systém" as const
const SLIDING = "posuvné" as const
const FIXED = "pevné" as const
const COMBINED = "kombinované" as const
const BARRIER_FREE = "Bezbariérový systém" as const
const GLASS_TEMPERED_THICKNESS = [{name:"6",code:"6"},{name:"8",code:"8"},{name:"10",code:"10"}] as const
const ANOTHER_PROFILES_THICKNESS = [{name:"170",code:170},{name:"175",code:175},{name:"177",code:177}] as const
const CAP_TYPE = [{name:"15",code:15},{name:"16",code:16}] as const
const OPENNING_DIRECTION = [{name:"pravé",code:"RIGHT"},{name:"ľavé",code:"LEFT"},{name:"obojstranné",code:"BOTH"},{name:"-",code:"NONE"}] as const
const OPENNING_DIRECTION_BKS = [{name:"pravé",code:"RIGHT"},{name:"ľavé",code:"LEFT"}] as const

const OPENNING_TYPES = [{name:"posuvné",code:"SLIDING"},{name:"pevné",code:"FIXED"},{name:"kombinované",code:"COMBINED"}] as const

const CASE_TYPE_NONE_RANGE = [{name:"0",code:"0"},{name:"3",code:"3"}] as const
const CASE_TYPE_60_RANGE = [
    { name: "40", code: "40" },
    { name: "41", code: "41" },
    { name: "42", code: "42" },
    { name: "43", code: "43" },
    { name: "44", code: "44" },
    { name: "45", code: "45" },
    { name: "46", code: "46" },
    { name: "47", code: "47" },
    { name: "48", code: "48" },
    { name: "49", code: "49" },
    { name: "50", code: "50" },
] as const;

const CASE_TYPE_90_RANGE = [
    { name: "70", code: "70" },
    { name: "71", code: "71" },
    { name: "72", code: "72" },
    { name: "73", code: "73" },
    { name: "74", code: "74" },
    { name: "75", code: "75" },
    { name: "76", code: "76" },
    { name: "77", code: "77" },
    { name: "78", code: "78" },
    { name: "79", code: "79" },
    { name: "80", code: "80" },
] as const;

const CASE_TYPES = [
    { name: "ŽIADNE", code: Models.CaseType.NONE },
    { name: "60", code: Models.CaseType.CASE60 },
    { name: "90", code: Models.CaseType.CASE90 },
] as const;


const OPENING_LABELS: { [key: string]: string } = {
    [SLIDING]: 'posuvné',
    [FIXED]: 'pevné',
    [COMBINED]: 'kombinované',
};

const CONNEX_THICKNESS = [{name:"3-1-3",code:"3-1-3"},{name:"4-1-4",code:"4-1-4"}] as const
const FIX_GLASS_TYPE = [{name:"číre",code:"číre"},{name:"mliečne",code:"mliečne"},{name:"lexan",code:"lexan"},{name:"obyčajné 4mm",code:"obyčajné 4mm"},{name:"obyčajné 6mm",code:"obyčajné 6mm"}] as const
const GLAZING_TYPE = [{name:"áno",code:"áno"},{name:"nie",code:"nie"}] as const
const GLAZING_TYPE_ATTRIBUTE = [{name:"číra",code:"číra"},{name:"mliečna",code:"mliečna"},{name:"bronzová",code:"bronzová"},{name:"-",code:"-"}] as const
const ROOF_TYPE = [{name:"hliníková",code:"ZINK"},{name:"pozinkovaná",code:"ALUMINIUM"},{name:"žiadna",code:"NONE"}] as const
const RAILING_TYPE = [{name:"žiadne",code:false},{name:"nové",code:true},{name:"pôvodné",code:false}] as const
const RAILING_IN_FILL_TYPE = [{name:"sklo",code:"GLASS"},{name:"lexan",code:"LEXAN"},{name:"žiadna",code:"NONE"}] as const
const GLASS_TYPE = [{name:"kalené dvojsklo",code:"kalené dvojsklo"},{name:"3-1-3",code:"3-1-3"}] as const
const RAMED_PROFILE_TYPE: OptionType[] = [];
const BINDER_PROFILE_TYPE: OptionType[] = [];

const HasBeenSent = false as Boolean;
const HasBeenInProduction = false as Boolean;
const HasBeenReady = false as Boolean;
const HasBeenFinished = false as Boolean;
const HasBeenEmailSent = false as Boolean;

const BINDER_OPTIONS = [{name:"0",code:0},{name:"1",code:1},{name:"2",code:2},{name:"3",code:3},{name:"4",code:4},{name:"5",code:5},{name:"6",code:6},{name:"7",code:7},{name:"8",code:8},{name:"9",code:9},{name:"10",code:10}] as const

export const store = reactive({
    Api_url: API_URL,
    token: "",
    admin: false,
    user: {...User},
    modifiedUser: {...User},
    type: {...Type},
    order: {...Order},
    fix: {...Fix},
    fixies: Fixes,
    fix_default:  {...FixDefault},
    profile: {...Profile},
    profiles: Profiles,
    binderProfile: {...Profile},
    binderProfiles: Profiles,
    profile_default: {...ProfileDefault},
    walls_default: {...WallsDefault},
    order_default: {...OrderDefault},
    types: Types,
    front_open: FRONT_OPEN,
    side_open: SIDE_OPEN,
    side_fixed: SIDE_FIXED,
    front: FRONT,
    left: LEFT,
    right: RIGHT,
    barrier: BARRIER,
    barrier_free: BARRIER_FREE,
    glass_tempered_thickness: GLASS_TEMPERED_THICKNESS,
    another_profiles_thickness: ANOTHER_PROFILES_THICKNESS,
    cap_type: CAP_TYPE,
    opening_direction: OPENNING_DIRECTION,
    opening_direction_bks: OPENNING_DIRECTION_BKS,
    opening_types: OPENNING_TYPES,
    opening_labels: OPENING_LABELS,
    glazing_type: GLAZING_TYPE,
    glazing_type_attribute: GLAZING_TYPE_ATTRIBUTE,
    glazing_type_attribute_transp: GLAZING_TYPE_ATTRIBUTE[0],
    roof_type: ROOF_TYPE,
    railing_type: RAILING_TYPE,
    connex_thickness: CONNEX_THICKNESS,
    fix_glass_types: FIX_GLASS_TYPE,
    railing_in_fill_type: RAILING_IN_FILL_TYPE,
    case_types: CASE_TYPES,
    case_type_range_0: CASE_TYPE_NONE_RANGE,
    case_type_range_60: CASE_TYPE_60_RANGE,
    case_type_range_90: CASE_TYPE_90_RANGE,
    glass_types: GLASS_TYPE,
    ramed_profile_type: RAMED_PROFILE_TYPE,
    binder_profile_type: BINDER_PROFILE_TYPE,
    orderHistory: OrderHistory,
    hasBeenSent: HasBeenSent,
    hasBeenInProduction: HasBeenInProduction,
    hasBeenReady: HasBeenReady,
    hasBeenFinished: HasBeenFinished,
    hasBeenEmailSent: HasBeenEmailSent,
    orderStates: OrderStates,
    binder_options: BINDER_OPTIONS,
    emailHistory: EmailHistory,
    emailRecipient: EmailRecipient,   
    emailHistories: EmailHistories,
    emailRecipients: EmailRecipients,
    emailRecipientOption: EmailRecipientOption,
    emailRecipientOptions: EmailRecipientOptions,
    emailLink: "",
    isSendEmail: false,
    pdfProductionLink: "",
    pdfProductionPrintLink: "",
    pdfBksProfileLink: "",
    pdfBksProfilePrintLink: "",
    dxfGlassesLink: "",
});