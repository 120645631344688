<template>
  <div class="container">
    <div class="row">

      <div class="col-md-8">
        <h2 class="mt-3">Zoznam objednávok</h2>
      </div>
      <DataTable
          :totalRecords="pagination.totalRecords"
          dataKey="id"
          :value="this.orders"
          v-model:filters="filters"
          :rowsPerPageOptions="[200,100,50]"
          filterDisplay="row" :loading="loading"
          :globalFilterFields="globalFilterFields"
          stripedRows
          row-hover
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :currentPage="pagination.page"
          @page="onPage"
      >
        <template #header>
          <div class="flex justify-content-end">
            <IconField iconPosition="left">
              <InputIcon>
                <i class="pi pi-search mr-2"/>
              </InputIcon>
              <InputText v-model="filters['global'].value" placeholder="Vyhľadať"/>
            </IconField>
          </div>
        </template>

        <Column field="orderNumber" header="ID"
                filterField="orderNumber"
                :showFilterMenu="false"
                :filterMenuStyle="{ width: '1rem' }" style="min-width: 1rem">
          <template #body="slotProps">
            {{ slotProps.data.orderNumber }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                       placeholder="Hľadať"/>
          </template>
        </Column>
        <Column field="user.company" header="Firma"
                filterField="user.company"
                :showFilterMenu="false" :filterMenuStyle="{ width: '1rem' }" style="min-width: 1rem"
                v-if="this.store.user.admin">
          <template #body="slotProps">
             {{ slotProps.data.user.company }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                       placeholder="Hľadať"/>
          </template>
        </Column>
        <Column field="user.lastName" header="Odberateľ"
                filterField="user.lastName"
                :showFilterMenu="false" :filterMenuStyle="{ width: '1rem' }" style="min-width: 1rem"
                v-if="this.store.user.admin">
          <template #body="slotProps">
            {{ slotProps.data.user.lastName }} {{ slotProps.data.user.firstName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                       placeholder="Hľadať"/>
          </template>
        </Column>
        <Column field="customerName" header="Zákazník"
                filterField="customerName"
                :showFilterMenu="false"
                :filterMenuStyle="{ width: '1rem' }" style="min-width: 1rem">
          <template #body="slotProps">
            {{ slotProps.data.customerName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                       placeholder="Hľadať"/>
          </template>
        </Column>
        <Column field="type.name" header="Zasklenie"></Column>


        <Column field="state" header="Status" :showFilterMenu="false"
                :filterMenuStyle="{ width: '14rem' }" style="min-width: 12rem">
          <template #body="{ data }">
            <Tag :value="data.state" :severity="getStateSeverity(data.state)"/>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown v-model="filterModel.value" @change="filterCallback()"
                      :options="statusLabels" placeholder="Vyber stav"
                      class="p-column-filter" style="min-width: 12rem" :showClear="true">
              <template #option="slotProps">
                <Tag :value="slotProps.option" :severity="getStateSeverity(slotProps.option)"/>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column header="Dátum">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.dateAdd) }}
          </template>
        </Column>
        <Column header="">
          <template #body="slotProps">

            <a  @click="showDetail(slotProps.data.id)" target="_blank">
              <PrimevueButton label="Zobraziť" text raised class="uppercase" icon="pi pi-eye" severity="info"/>
            </a>

          </template>
        </Column>        <Column header="">
          <template #body="slotProps">

            <a  @click="deleteOrder(slotProps.data.id)" target="_blank">
              <PrimevueButton label="Odstrániť" text raised icon="pi pi-trash" severity="danger"/>
            </a>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

</template>

<script lang="ts">

import Tag from 'primevue/tag';
import {store} from "@/components/store";
import Security from "@/components/security";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import Dropdown from "primevue/dropdown";
import InputIcon from "primevue/inputicon";
import notie from "notie/dist/notie";
import PrimevueButton from "primevue/button";
import Utils from '@/components/utils';

export default {
  components: {
    DataTable,
    Column,
    Tag,
    Dropdown,
    InputIcon,
    PrimevueButton

  },
  name: "OrderList",
  data() {
    return {
      store,
      orders: [],
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        orderNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
        'user.company': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'user.lastName': {value: null, matchMode: FilterMatchMode.CONTAINS},
        customerName: {value: null, matchMode: FilterMatchMode.CONTAINS},
        state: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      globalFilterFields: ['orderNumber', 'customerName', 'state'],
      statuses: [],
      statusLabels: [],
      loading: true,
      lazyParams: [],
      pagination: {
        page: 1,
        rows: 20,
        totalRecords: 0, // Total number of records for pagination
      },
    }
  },
  beforeMount() {
    Security.requireToken();

    if (store.user.admin) {
      this.globalFilterFields = ['orderNumber', 'user.company', 'user.lastName', 'customerName', 'state']
    }

    fetch(store.Api_url + "/v1/order?sortby=id&order=desc&query=active:1", Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            console.log("error:", responseData.message);
            this.$emit('error', responseData.message);
          } else {
            this.orders = responseData.data
            // Corrected forEach usage
            this.orders.forEach((order) => {
              order.state = order.currentState.label;
            });
            this.pagination.totalRecords = responseData.totalRecords
            this.loading = false
            console.log('total',this.pagination.totalRecords);
          }
        })
        .catch((error) => {
          alert('chyba pri nacitani objednavok: ' + error)
        })

    fetch(store.Api_url + "/v1/order-state?query=active:1", Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            console.log("error:", responseData.message);
            this.$emit('error', responseData.message);
          } else {
            console.log(responseData);
            this.statuses = responseData
            this.statuses.forEach((status) => {
              this.statusLabels.push(status.label)
            });
            this.loading = false
          }
        })
        .catch((error) => {
          alert('chyba pri nacitani stavov: ' + error)
        })
  },
  methods: {
    formatDate(dateString: string): string {
      // Parse the date string
      ///const date = new Date(dateString);
      ///const day = date.getDate();
      ///const month = date.getMonth() + 1; // getMonth() returns 0-11
      ///const year = date.getFullYear();
      ///const hours = date.getHours(); // Adjust this if you need to handle timezone conversion manually
      ///const minutes = date.getMinutes();
      ///const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
      ///return `${day}.${month}.${year} ${hours}:${formattedMinutes}`;
      return Utils.parseToSlovakDateTime(dateString);
      
    },
    buttonClicked(rowData) {
      alert(`Button clicked for ${rowData.name}`);
    },
    // load additional order data and store it in store.order obj
    showDetail(orderId) {
      router.push('/order/' + String(orderId))
    },
    deleteOrder(orderId){
      if(confirm("Naozaj chcete ODSTRÁNIŤ objednávku?")){
        Security.requireToken();
        fetch(store.Api_url + "/v1/order/" + String(orderId), Security.requestOptions("DELETE"))
            .then((response) => response.json())
            .then((responseData) => {
              if (responseData.error) {
                console.log("error:", responseData.message);
                this.$emit('error', responseData.message);
              } else {
                // Make a copy of the orders array to avoid mutating during iteration
                const updatedOrders = [...this.orders];

                // Find and remove the order with the matching ID
                const indexToRemove = updatedOrders.findIndex(order => order.id === orderId);
                if (indexToRemove !== -1) {
                  updatedOrders.splice(indexToRemove, 1);
                }

                // Update the UI with the modified orders array
                this.orders = updatedOrders;
                notie.alert({
                  type: 'success',
                  text: "Objednávka bola odstránená",
                  position: "bottom"
                })
              }
            })
            .catch((error) => {
              alert('chyba pri nacitani objednavok: ' + error)
            })
      }
    },
    getStateSeverity(reference: string): string {
      switch (reference) {
        case "Odoslaná":
          return "danger";
        case "Vo výrobe":
          return "warning";
        case "Pripravená":
          return "success";
        case "Ukončená":
          return "contrast";
        default:
          return "info";
      }
    },
    onPage(event) {
      this.pagination.page = event.page + 1; // PrimeVue page is zero-based; adjust if your API is 1-based
      this.pagination.rows = event.rows;
      this.lazyParams = event;
      this.loadLazyData(event);
    },
    onSort(event) {
      this.lazyParams = event;
      this.loadLazyData(event);
    },
    loadLazyData() {
      this.loading = true;

      let queryParams = `&limit=${this.pagination.rows}&offset=${(this.pagination.page - 1) * this.pagination.rows}`;

      fetch(`${store.Api_url}/v1/order?sortby=id&order=desc&query=active:1${queryParams}`, Security.requestOptions("GET"))
          .then(response => response.json())
          .then(responseData => {
            if (responseData.error) {
              console.error("error:", responseData.message);
              this.$emit('error', responseData.message);
            } else {
              this.orders = responseData.data;
              this.orders.forEach(order => {
                order.state = order.currentState.label;
              });
              this.pagination.totalRecords = responseData.totalRecords;
              this.loading = false;
            }
          })
          .catch(error => {
            alert('Error fetching data: ' + error);
            this.loading = false;
          });
    }
  }

}
</script>

<style>
.p-datatable-thead .p-filter-column{
  padding:0;
}
.p-inputtext{
  padding: 0.3rem;
}
.p-column-filter .p-inputtext{
  padding: 0.3rem;
}
.p-dropdown-label.p-inputtext.p-placeholder{
  padding: 0.3rem;
}
.p-datatable .p-datatable-thead > tr > th{
  padding: 0.5rem;
}

.p-datatable button{
  border-radius: 6px;
  padding: 0.5rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td {

  padding: 0.5rem 0.5rem;
}

</style>