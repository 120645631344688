<template>
<div class="border-round-lg border-1 border-pink-500">
    <!-- <InputGroup class="h-2rem mt-1">
        <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Pozícia</InputGroupAddon>
        <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].position}}</InputGroupAddon>
        <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
    </InputGroup>  
    -->
    <div class="surface-border border-round-top-lg justify-content-center bg-pink-300">
         <h6 class="text-center uppercase">Priečnik  #{{(this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].position + 1)}}</h6>
    </div>
    <div class="p-1">

      <InputGroup class="mt-1">
        <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start">Vzdialenosť z ľava
        </InputGroupAddon>
        <InputNumber
            class="w-3 justify-content-end "
            inputStyle="background: #b8d6f0; text-align: right; height: 2.1rem"
            name="leftOffset"
            id="leftOffset"
            required="required"
            v-model="this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].leftOffset"
            :autocomplete="`leftOffset-new`"
            @input="changeInput"
        >
        </InputNumber>
        <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
      </InputGroup>

      <InputSwitch v-if="this.fixBinderKey == 0"
                   id="leftOffsetLock_0"
                   v-model="this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].lockLeftOffset"
                   class="mt-2 offset-lock offset-lock-left"
                   @input="changeInput"
      ></InputSwitch>
      <label v-if="this.fixBinderKey == 0"
             for="leftOffsetLock_0" class="ml-2">Zamknúť zľava</label>


      <InputGroup v-if="this.fixBinderKey == (this.store.order.fixes[this.$props.fixKey].fixBinders.length - 1)" class="mt-1">
        <InputGroupAddon class="w-7 font-normal text-base text-700 justify-content-start">Vzdialenosť z prava
        </InputGroupAddon>
        <InputNumber
            class="w-3 justify-content-end"
            inputStyle="background: #b8d6f0; text-align: right; height: 2.1rem"
            name="rightOffset"
            id="rightOffset"
            required="required"
            v-model="this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].rightOffset"
            :autocomplete="`rightOffset-new`"
            @input="changeInput"
        >
        </InputNumber>
        <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
      </InputGroup>


      <InputSwitch v-if="this.fixBinderKey == (this.store.order.fixes[this.$props.fixKey].fixBinders.length - 1)"
                   id="rightOffsetLock_last"
                   v-model="this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].lockRightOffset"
                   class="mt-2 offset-lock offset-lock-right"
                   @input="changeInput"
      ></InputSwitch>
      <label v-if="this.fixBinderKey == (this.store.order.fixes[this.$props.fixKey].fixBinders.length - 1)"
             for="rightOffsetLock_last" class="ml-2">Zamknúť zprava</label>

        <InputGroup class="mt-1">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">
              <span v-if="this.store.order.fixes[this.$props.fixKey].atypical">Výška A</span>
              <span v-else>Výška</span>
            </InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].heightA}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>
        <InputGroup class="mt-1" v-if="this.store.order.fixes[this.$props.fixKey].atypical">
            <InputGroupAddon class="w-7  font-normal text-base text-700 justify-content-start">Výška B</InputGroupAddon>
            <InputGroupAddon class="w-3  font-normal text-base text-700 ">{{this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].heightB}}</InputGroupAddon>
            <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
        </InputGroup>  
    </div> 
</div>
</template>

<script lang="ts">

//import Security from '../security';
//import notie from 'notie/dist/notie' 
import {store} from '../store';
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
//import {Models} from '../models';

export default {
    name: 'CardFixBinder',
    components:{
      InputNumber,
      InputSwitch,

    }, 
    props: {
        fixKey: Number,
        fixBinderKey: Number,
        validationOK: Boolean,
        validationAttr: String,
        
    },
    data() {
      return {
        store,
        labelFixLeft: String,
        labelFixRight: String,
        //ramedProfileId: String,
        //binderProfileId: String,
      }
    },
    methods: {
        changeInput(data:any): void{
            //this.store.order.fixes[this.$props.fixKey].fixBinders[this.fixBinderKey].leftOffset = data.value;
            this.$emit('change-fix-binder',{fixBinderKey:this.$props.fixBinderKey,fixBinderValue:data.value})
        }
    },
  beforeMount(){
      this.labelFixLeft =  "Zamknúť zľava"
  }
}
</script>

<style scoped>

.p-inputgroup {
  height: 2rem; 
  /*margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;*/
}
.p-inputgroup-addon {
  padding: 1rem 0.5rem;
 }
 .p-dropdown {
  height: 2.15rem;
  padding: 0.2rem;
  background: #b8d6f0;
 }
 .p-inputtext{
  background: #b8d6f0; 
  text-align: left;
  height: 2rem; 
}
.p-inputtext.p-component.p-inputnumber-input {
  height: 2.1rem;
}
.p-inputswitch {
  width: 2.5rem;
  height: 1rem;
}
</style>
