<template>
  <div class="surface-50 relative">

    <div class="p-2 surface-border border-bottom-2 flex flex-column md:flex-row md:justify-content-between">
      <div class="pb-2 pt-2">Odberateľ: {{ this.store.order.user.company }} (<em>{{ this.store.order.user.firstName }}
        {{ this.store.order.user.lastName }}, {{ this.store.order.user.phone }}</em>)
      </div>
      <div>Aktuálny stav:
        <Tag class="p-2 ml-1 font-medium text-base" :value="this.store.order.currentState.label"
             :severity="Utils.getStateSeverity(this.store.order.currentState.reference)"/>
      </div>
      <div class="p-2 ml-5" v-if="this.store.order.id > 0">
        <em>Objednávka naposledy uložená
          <i class="pi pi-clock"></i>
          {{ Utils.parseToSlovakDateTime(this.store.order.modifiedDate) }} - {{
            this.store.order.modifiedUser.firstName
          }}
          {{ this.store.order.modifiedUser.lastName }}
        </em>
      </div>
    </div>
    <div class="p-2 surface-border border-bottom-2 flex flex-row flex-wrap align-items-center">
      <div class="pb-2 pt-2 font-medium text-xl uppercase">
        #{{ this.store.order.orderNumber }} - {{ this.store.type.name }} - {{ this.typeBarrierName }}
        <!--          <Button label="External" :href="pdfMaterialLink"  icon="pi pi-check" severity="info" />-->
      </div>
      <div v-if="this.store.user.admin === true && this.isBarrier && this.store.order.type.code != TypeCode.B_F_H" class="flex flex-row flex-wrap">

        <a v-if="this.pdfMaterialScreenLink !== '' " :href="this.pdfMaterialScreenLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="PDF materiál" text raised class="uppercase" icon="pi pi-eye"/>
        </a>


        <a v-if="this.pdfGlassScreenLink !== ''" :href="this.pdfGlassScreenLink" class="mr-5" target="_blank">
          <PrimevueButton label="PDF Sklá" text raised class="uppercase" icon="pi pi-eye"/>
        </a>
      </div>

      <div v-if="this.store.user.admin === true &&  this.store.order.type.code == TypeCode.B_F_H" class="flex flex-row flex-wrap">
        <a v-if="this.pdfProductionLink !== '' " :href="this.pdfProductionLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="Zobraziť PDF výrobný list" text raised class="uppercase" icon="pi pi-eye"/>
        </a>
        <a v-if="this.pdfProductionPrintLink !== '' " :href="this.pdfProductionPrintLink" class=" mr-5 ml-5" target="_blank">
          <PrimevueButton label="Tlačiť PDF výrobný list" text raised class="uppercase" icon="pi pi-print"/>
        </a>

        <a v-if="this.dxfGlassLink !== ''" :href="this.dxfGlassLink" class="mr-5" target="_blank">
          <PrimevueButton label="DXF Sklá" text raised class="uppercase" icon="pi pi-download"/>
        </a>
      </div>
    </div>

    <div class="flex flex-row flex-wrap h-full">
      <div class="w-6 m-2 p-2 surface-card shadow-2 border-round-lg h-full align-items-center justify-content-center">
        <InputGroup class="h-2rem">
          <InputGroupAddon class="wfloorlab h-full font-normal text-base text-700 justify-content-start">Meno a priezvisko
          </InputGroupAddon>
          <InputText
              class="justify-content-start wfloorinp"
              inputStyle="background: #b8d6f0; text-align: left"
              name="nameSurname"
              id="nameSurname"
              placeholder="Zadaj meno a priezvisko"
              required:string="required"
              v-model="this.store.order.customerName"
              autocomplete="nameSurname-new"

          >
          </InputText>
          <InputGroupAddon class="wterminlab h-full font-normal text-base text-700 justify-content-start">Mobilný kontakt
          </InputGroupAddon>
          <InputText class="wtermincal justify-content-start" inputStyle="background: #b8d6f0; text-align: left"
                     name="phone"
                     id="phone"
                     placeholder="Zadaj telefón"
                     required:string="required"
                     v-model="this.store.order.phone"
                     autocomplete="phone-new"

          >
          </InputText>

        </InputGroup>
        <InputGroup class="h-2rem mt-2">
          <InputGroupAddon class="wfloorlab h-full font-normal text-base text-700 justify-content-start">Adresa
          </InputGroupAddon>
          <InputText
              class="justify-content-start wfloorinp"
              inputStyle="background: #b8d6f0; text-align: left"
              name="address"
              id="address"
              placeholder="Zadaj adresu"
              required:string="required"
              v-model="this.store.order.address"
              autocomplete="address-new"

          >
          </InputText>
          <InputGroupAddon class="wterminlab h-full font-normal text-base text-700 justify-content-start">E-mail
          </InputGroupAddon>
          <InputText type="email" class="wtermincal justify-content-start" inputStyle="background: #b8d6f0; text-align: left"
                     name="email"
                     id="email"
                     placeholder="Zadaj e-mail"
                     required:string="required"
                     v-model="this.store.order.email"
                     autocomplete="email-new"

          >
          </InputText>

        </InputGroup>
        <InputGroup class="h-2rem mt-2">
          <InputGroupAddon class="wfloorlab-2 h-full font-normal text-base text-700 justify-content-start">Poschodie
          </InputGroupAddon>
          <InputNumber
              inputId="poschodie"
              class="justify-content-start wfloorinp-2"
              inputStyle="background: #b8d6f0; text-align: left"
              name="floor"
              id="floor"
              placeholder="Zadaj poschodie"
              required:string="required"
              v-model="this.store.order.floor"
              autocomplete="floor-new"

          >
          </InputNumber>
          <InputGroupAddon class="wterminlab h-full font-normal text-base text-700 justify-content-start">Termín montáže
          </InputGroupAddon>
          <Calendar class="wtermincal-2"
                    v-model="termin"
                    showIcon
                    dateFormat="dd.mm.yy"
          />
        </InputGroup>
      </div>
      <div class="m-2 p-2  shadow-2 border-round-lg h-full align-items-center justify-content-center" v-if="this.isBarrier">

        <!--  STRIESKA A PARAPET-->

        <div class="w-full flex flex-row flex-wrap mb-2">

          <div v-if="this.store.order.parapet == true">
            <div>Parapet</div>
            <img src="/img/parapet-001.png" width="150" alt="Parapet">
          </div>

          <div v-if="this.store.order.roof !== 'NONE'">
            <div>Strieška</div>
            <img src="/img/strieska.png" width="150" alt="Strieška">
          </div>
        </div>

        <div class="w-full flex flex-row flex-wrap">
          <div class="w-18rem h-2rem flex flex-row flex-wrap">
            <div class="w-4 align-items-center justify-content-center">
              <label for="parapet" class="text-700 pt-1 mr-2">Parapet</label>
              <Checkbox class="" v-model="this.store.order.parapet" :binary="true" inputId="parapet"/>
            </div>
            <InputGroup v-if="this.store.order.parapet == true" class="w-7 h-2rem">

              <InputGroupAddon class="w-1 font-normal text-base text-700 justify-content-start">A</InputGroupAddon>
              <InputNumber
                  inputId="integeronly"
                  class="justify-content-start w-1"
                  inputStyle="background: #b8d6f0; text-align: left; padding-left: 0.2rem; padding-right: 0.1rem"
                  name="parapetA"
                  id="parapetA"
                  placeholder=""
                  required:string="required"
                  v-model="this.store.order.parapetDimA"
                  autocomplete="parapetA-new"

              >
              </InputNumber>
              <InputGroupAddon class="w-1 font-normal text-base text-700 justify-content-start">B</InputGroupAddon>
              <InputNumber
                  inputId="integeronly"
                  class="justify-content-start w-1"
                  inputStyle="background: #b8d6f0; text-align: left; padding-left: 0.2rem; padding-right: 0.1rem"
                  name="parapetB"
                  id="parapetB"
                  placeholder=""
                  required:string="required"
                  v-model="this.store.order.parapetDimB"
                  autocomplete="parapetB-new"

              >
              </InputNumber>
            </InputGroup>
          </div>
          <div class="w-25rem h-2rem ml-2 flex flex-row flex-wrap">
            <InputGroup class="flex w-6 h-full">
              <InputGroupAddon class="w-4 p-myroof h-1.9rem font-normal text-base text-700 justify-content-start">
                Strieška
              </InputGroupAddon>
              <Dropdown
                  v-model="this.store.order.roof"
                  :options="this.store.roof_type"
                  optionLabel="name"
                  optionValue="code"
                  :placeholder="this.store.order.roof"
                  class="w-2 h-2.1rem" inputStyle="padding-left: 0.2rem; padding-top: 0.3rem"
              />
            </InputGroup>
            <InputGroup v-if="this.store.order.roof !== 'NONE'" class="flex w-5 h-2rem ml-2">
              <InputGroupAddon class="w-1 font-normal text-base text-700 justify-content-start">A</InputGroupAddon>
              <InputNumber
                  inputId="integeronly"
                  class="justify-content-start w-1"
                  inputStyle="background: #b8d6f0; text-align: left; padding-left: 0.2rem; padding-right: 0.1rem"
                  name="roofA"
                  id="roofA"
                  placeholder=""
                  required:string="required"
                  v-model="this.store.order.roofDimA"
                  autocomplete="roofA-new"

              >
              </InputNumber>
              <InputGroupAddon class="w-1 font-normal text-base text-700 justify-content-start">B</InputGroupAddon>
              <InputNumber
                  inputId="integeronly"
                  class="justify-content-start w-1"
                  inputStyle="background: #b8d6f0; text-align: left; padding-left: 0.2rem; padding-right: 0.1rem"
                  name="roofB"
                  id="roofB"
                  placeholder=""
                  required:string="required"
                  v-model="this.store.order.roofDimB"
                  autocomplete="roofB-new"

              >
              </InputNumber>
            </InputGroup>
          </div>
        </div>
        <div class="w-full mt-3">
          <InputGroup class="w-full h-2rem">
            <InputGroupAddon class="w-4 h-full font-normal text-base text-700 justify-content-start">Zábradlie
            </InputGroupAddon>
            <Dropdown
                v-model="this.store.order.railingReplace"
                :options="this.store.railing_type"
                optionLabel="name"
                optionValue="code"
                :placeholder="this.store.order.railingReplace"
                class="w-3 h-2.1rem" inputStyle="padding-left: 0.2rem; padding-top: 0.3rem"
            />
            <InputGroupAddon class="w-4 h-full font-normal text-base text-700 justify-content-start">Výplň
            </InputGroupAddon>
            <Dropdown
                v-model="this.store.order.railingInfill"
                :options="this.store.railing_in_fill_type"
                optionLabel="name"
                optionValue="code"
                :placeholder="this.store.order.railingInfill"
                class="w-3 h-2.1rem" inputStyle="padding-left: 0.2rem; padding-top: 0.3rem"
            />
          </InputGroup>
          <InputGroup class="w-full h-2rem">
            <InputGroupAddon class="w-1 h-full font-normal text-base text-700 justify-content-start">Pozn.
            </InputGroupAddon>
            <InputText type="email" class="w-full justify-content-start"
                       inputStyle="background: #b8d6f0; text-align: left"
                       name="railingComment"
                       id="railingComment"
                       placeholder="Zadaj poznámku k zábradliu"
                       v-model="this.store.order.railingComment"
                       autocomplete="railingComment-new"
            >
            </InputText>
          </InputGroup>
        </div>
        <!-- //  STRIESKA A ZABRADLIE-->
      </div>
    </div>
    <div class="flex flex-row flex-wrap shadow-2 bg-red-300 border-round-lg m-2 alert alert-danger"
         v-if="this.validationError != ''">
      {{ this.validationError }}
    </div>
    <div class="flex flex-row flex-wrap surface-50" v-if="this.isBarrier">
      <div class="align-items-center justify-content-center w-3 surface-card shadow-2 border-round-lg m-2">
        <glazing-wall
            :typeWall="position.FRONT"
            :isBarrier="this.isBarrier"
            :typeCode="this.store.type.code"
            :validationOK="this.validationOK"
            :validationAttr="this.validationAttr"
        ></glazing-wall>
      </div>
      <div v-if="this.isLeft"
           class="align-items-center justify-content-center w-3 surface-card shadow-2 border-round-lg m-2">
        <glazing-wall
            :typeWall="position.LEFT"
            :isBarrier="this.isBarrier"
            :validationOK="this.validationOK"
            :validationAttr="this.validationAttr"
        ></glazing-wall>
      </div>
      <div v-if="this.isRight"
           class="align-items-center justify-content-center w-3 surface-card shadow-2 border-round-lg m-2">
        <glazing-wall
            :typeWall="position.RIGHT"
            :isBarrier="this.isBarrier"
            :validationOK="this.validationOK"
            :validationAttr="this.validationAttr"

        ></glazing-wall>
      </div>
    </div>
    <div class="flex flex-row flex-wrap" v-if="!this.isBarrier">
      <div class="align-items-center justify-content-center w-12 surface-card shadow-2 border-round-lg m-2">
        <bks-wall
            :typeWall="position.FRONT"
            :isBarrier="this.isBarrier"
            :validationOK="this.validationOK"
            :validationAttr="this.validationAttr"
        ></bks-wall>
      </div>
    </div>
    <div
        class="w-9 ml-2 mt-2 surface-card shadow-2 border-round-lg h-full flex flex-row flex-wrap align-items-center justify-content-center">
      <div class="w-4 p-2">
        <label for="additions">Doplňujúci materiál</label>
        <TextArea id="additions" autoResize rows="2" class="w-full"
                  placeholder="Zadaj doplňujúci materiál"
                  v-model="this.store.order.additions"
                  autocomplete="additions-new"
        />
      </div>
      <div class="w-4 p-2">
        <label for="comments">Poznámky</label>
        <TextArea id="comments" autoResize rows="2" class="w-full"
                  placeholder="Zadaj poznámky"
                  v-model="this.store.order.comment"
                  autocomplete="comments-new"

        />
      </div>
      <div class="w-4 p-2">
        <label for="comments">Poznámka ku sklám</label>
        <TextArea id="comments" autoResize rows="2" class="w-full"
                  placeholder="Zadaj poznámku k výrobe skla"
                  v-model="this.store.order.glassesComment"
                  autocomplete="comments-new"

        />
      </div>
    </div>
    <div class="flex flex-row flex-wrap">
        <Card class="shadow-2 m-2 w-3">
          <template #title>Prehľad stavov</template>
          <template #content>
            <ul>
              <li v-for="history in this.store.orderHistory" v-bind:key="history.id">
                <i class="pi pi-clock"></i>
                {{ Utils.formatToSlovak(history.createDate) }}
                <Tag :value="history.state.label" :severity="Utils.getStateSeverity(history.state.reference)"/>
                - {{ history.user.firstName }} {{ history.user.lastName }}
              </li>
            </ul>
          </template>
        </Card>

        <Card v-if="this.store.emailHistories.length > 0" class="shadow-2 m-2 w-6">
          <template #title>Prehľad histórie zaslaných emailov</template>
          <template #content>
            <ul>
              <li v-for="history in this.store.emailHistories" v-bind:key="history.id">
                <i class="pi pi-clock"></i>
                {{ Utils.formatToSlovak(history.createDate) }}
              <!--  <Tag :value="history.state.label" :severity="Utils.getStateSeverity(history.state.reference)"/> -->
                Od: <span class="mr-1 font-medium">{{ history.user.firstName }} {{ history.user.lastName }}   </span> 
                Komu: <span class="mr-1 font-medium">{{ history.whom }}   </span>  Predmet: <span class="font-medium">{{ history.subject }}</span>
              </li>
            </ul>
          </template>
        </Card>
    </div>
  </div>

  <!--  floating button -->
  <div class="bottom-action-row">
    <a class="mr-5"
       @click="saveOrder()">
      <PrimevueButton label="ULOŽIŤ a PREPOČÍTAŤ" raised class="uppercase" severity="info" icon="pi pi-save"/>
    </a>

    <a v-if="this.store.order.id > 0" class="mr-2 ml-2"
       @click="!this.store.hasBeenSent ? Utils.changeState(Models.State.SENT) : null">
      <PrimevueButton label="Odoslať" raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.SENT)"
                      :disabled="this.store.hasBeenSent"
      />
    </a>

    <a v-if="this.store.user.admin" class="mr-2 ml-2"
       @click="!this.store.hasBeenInProduction && this.store.hasBeenSent ? Utils.changeState(Models.State.PRODUCTION): null">
      <PrimevueButton label="Nastaviť výrobu" raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.PRODUCTION)"
                      :disabled="this.store.hasBeenInProduction || !this.store.hasBeenSent"
      />
    </a>

    <a v-if="this.store.user.admin" class="mr-2 ml-2"
       @click="!this.store.hasBeenReady && this.store.hasBeenInProduction ? Utils.changeState(Models.State.READY): null">
      <PrimevueButton label="Pripravená na prevzatie" raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.READY)"
                      :disabled="this.store.hasBeenReady ||  !this.store.hasBeenInProduction || !this.store.hasBeenSent"
      />
    </a>
    <a v-if="this.store.user.admin" class="mr-2 ml-2"
       @click="!this.store.hasBeenFinished && this.store.hasBeenReady ? Utils.changeState(Models.State.FINISH): null">
      <PrimevueButton label="Ukončená" raised class="uppercase"
                      :severity="Utils.getStateSeverity(Models.State.FINISH)"
                      :disabled="!this.store.hasBeenReady || this.store.hasBeenFinished"
      />
    </a>

    <a v-if="this.store.emailLink !== ''" @click="this.store.isSendEmail = !this.store.isSendEmail">
      <PrimevueButton label="Email" :severity="this.store.hasBeenEmailSent ? 'warning' : 'success'" raised class="uppercase ml-5" icon="pi pi-envelope" />
      <em v-if="this.store.hasBeenEmailSent" class="ml-1 ext-muted fs-6 text-lg text-yellow-500"><small>email bol už zaslaný</small></em>
    </a>

    <EmailList v-if="this.store.isSendEmail && this.store.emailLink !== ''" v-model="this.recipId"
               :options="this.store.emailRecipientOptions"
               optionLabel="name" optionValue="id"
               class="md:w-30rem absolute email-list"
               @change="sendEmail()"

    />

  </div>
</template>

<script lang="ts">

import Security from '../security'
//import notie from 'notie/dist/notie'
import {store} from '../store'
import GlazingWall from "@/components/forms/GlazingWall.vue";
import BksWall from "@/components/forms/BksWall.vue";
import {Models, WallType} from '../models';
//import {BksGlass} from '../models';
import Utils from '../utils';
// import router from "@/router";
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import TextArea from 'primevue/textarea';
import notie from 'notie/dist/notie';
import PrimevueButton from 'primevue/button';
import Tag from "primevue/tag";
import Card from "primevue/card";
import EmailList from "primevue/listbox";
import Opening = Models.Opening;
import TypeCode = Models.TypeCode;

export default {
  name: 'FormInput',
  computed: {
    TypeCode() {
      return TypeCode
    },
    Models() {
      return Models
    },
    Utils() {
      return Utils
    },
  },
  components: {
    GlazingWall,
    BksWall,
    Calendar,
    Checkbox,
    Dropdown,
    TextArea,
    PrimevueButton,
    Tag,
    Card,
    EmailList,
  },
  data() {
    return {
      store,
      typeBarrierName: String,
      isBarrier: Boolean,
      validationOK: Boolean,
      validationError: String,
      validationAttr: String,
      position: Models.Position,
      isLeft: false as Boolean,
      isRight: false as Boolean,
      pdfMaterialLink: String,
      pdfMaterialScreenLink: String,
      pdfMaterialPrintLink: String,
      dfxGlassLink: String,
      pdfGlassLink: String,
      pdfGlassScreenLink: String,
      pdfProductionLink: String,
      pdfProductionPrintLink: String,
      pdfBksProfileLink: String,
      pdfBksProfilePrintLink: String,
      //emailLink : String,
      recipId : Number,
      //isSendEmail : false,
      termin: null,
    }
  },
  methods: {
    sendEmail(){
      this.store.isSendEmail = false;
      if(this.recipId == null){
        this.recipId = 1
      }

      this.store.emailRecipient = this.store.emailRecipients.filter(obj => obj.id == this.recipId)[0];

      if(this.store.order.type.code == Models.TypeCode.NB_F){
        Utils.sendingEmail("bks",this.recipId)
      }else{
        Utils.sendingEmail("glasses",this.recipId)
      }
      this.recipId = 1
    },

    validateHeights(): void {
      // check if wall heights are equal

      this.validationOK = true
      this.validationError = ""
      this.validationAttr = ""

      let fwSliding = false;
      let lwSliding = false;
      let rwSliding = false;
      let fwHeight = 0;
      let lwHeight = 0;
      let rwHeight = 0;
      this.store.order.walls.forEach((wall: WallType) => {
        if (wall.opening === Opening.SLIDING || wall.opening === Opening.COMBINED) {

          switch (wall.position) {
            case Models.Position.FRONT:
              fwSliding = true;
              fwHeight = wall.height;
              console.log("fwlHeight: ", fwHeight)
              break;
            case Models.Position.LEFT:
              lwSliding = true;
              lwHeight = wall.height;
              console.log("lwHeight: ", lwHeight)
              break;
            case Models.Position.RIGHT:
              rwSliding = true;
              rwHeight = wall.height
              console.log("rwHeight: ", rwHeight)
              break;
          }
        }
      });

      if (fwSliding && lwSliding && rwSliding) {
        if ((fwHeight != lwHeight) || (fwHeight != rwHeight)) {
          this.validationOK = false
          this.validationError = "Steny nemajú rovnakú výšku!"
          this.validationAttr = "height"
        }
      } else if (fwSliding && lwSliding) {
        if (fwHeight != lwHeight) {
          this.validationOK = false
          this.validationError = "Ľavá a predná stena nemajú rovnakú výšku!"
          this.validationAttr = "height"
        }
      } else if (fwSliding && rwSliding) {
        if (fwHeight != rwHeight) {
          this.validationOK = false
          this.validationError = "Pravá a predná stena nemajú rovnakú výšku!"
          this.validationAttr = "height"
        }
      }
      if (this.validationOK === false) {
        this.$emit('error', this.validationError);
        console.log(this.validationError)

        notie.alert({
          type: 'error',
          text: this.validationError
        })
      }
    },
    saveOrder(): void {

      this.validateHeights();
      this.store.order.installationDate = Utils.saveDate(this.termin);

      if (this.store.order.id === 0) {

        fetch(this.store.Api_url + "/v1/order", Security.requestOptions("POST", this.store.order))
            .then((response) => response.json())
            .then((responseData) => {
              if (responseData.error) {
                console.log("error:", responseData.message);
                this.$emit('error', responseData.message);
              } else {
                console.log('first saving data', responseData);
                //let types: Models.LayoutType[] = []
                this.store.order.id = responseData.data.id;
                this.store.order.orderNumber = responseData.data.orderNumber
                this.store.order.user = responseData.data.user

                this.pdfMaterialLink = this.store.Api_url + '/pdf/m/' + this.store.order.id
                this.pdfMaterialScreenLink = this.store.Api_url + '/pdf/m/' + this.store.order.id + '?format=screen'
                this.pdfMaterialPrintLink = this.store.Api_url + '/pdf/m/' + this.store.order.id + '?format=print'
                this.pdfGlassLink = this.store.Api_url + '/pdf/g/' + this.store.order.id
                this.pdfGlassScreenLink = this.store.Api_url + '/pdf/g/' + this.store.order.id + '?format=screen'
                this.store.emailLink = this.store.Api_url + '/email/' + this.store.order.id

                this.store.pdfProductionLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=screen'
                this.store.pdfProductionPrintLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=print'
                this.store.pdfBksProfileLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=screen'
                this.store.pdfBksProfilePrintLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=print'


                responseData.data.walls.forEach((wall: WallType) => {
                  let index = this.store.order.walls.findIndex(obj => obj.position === wall.position && obj.opening === wall.opening);
                  //console.log("Wall :",wall.Id);

                  this.store.order.walls[index].id = wall.id;
                  this.store.order.walls[index].bksGlasses = wall.bksGlasses;

                  this.dxfGlassesLink = this.store.Api_url + '/dxf/bks/' + this.store.order.walls[index].id + '?format=download'
                });

                Utils.updateOrderHistory(this.store.order.id);
                this.editOrder(String(this.store.order.id));


              }
            });

      } else {
        this.editOrder(String(this.store.order.id));
      }


    },
    editOrder(orderId: String) {

      fetch(this.store.Api_url + "/v1/order/" + orderId, Security.requestOptions("PUT", this.store.order))
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.error) {
              //console.log("error:", responseData.message);
              this.$emit('error', responseData.message);
              notie.alert({
                type: 'danger',
                text: "Problém s uložením",
                position: "bottom"
              })
            } else {
              //console.log("response walls:", responseData);

              responseData.data.walls.forEach((wall: WallType) => {
                let index = this.store.order.walls.findIndex(obj => obj.position === wall.position && obj.opening === wall.opening);
                if (index == -1) {
                  index = 0
                }
                
                this.store.order.walls[index].profilesLength = wall.profilesLength;
                this.store.order.walls[index].singleProfileLength = wall.singleProfileLength;
                this.store.order.walls[index].totalProfilesLength = wall.totalProfilesLength;
                this.store.order.walls[index].sideGlassWidth = wall.sideGlassWidth;
                this.store.order.walls[index].sideGlassCount = wall.sideGlassCount;
                this.store.order.walls[index].middleGlassWidth = wall.middleGlassWidth;
                this.store.order.walls[index].middleGlassCount = wall.middleGlassCount;

                this.store.order.walls[index].fixLeftProfileWidth = wall.fixLeftProfileWidth;
                this.store.order.walls[index].fixLeftGlassWidth = wall.fixLeftGlassWidth;
                this.store.order.walls[index].fixRightProfileWidth = wall.fixRightProfileWidth;
                this.store.order.walls[index].fixRightGlassWidth = wall.fixRightGlassWidth;
                this.store.order.walls[index].centerRailingWidth = wall.centerRailingWidth;
                this.store.order.walls[index].comment = wall.comment;

                this.store.order.walls[index].fixedGlassWidth = wall.fixedGlassWidth;
                this.store.order.walls[index].fixedGlassHeight = wall.fixedGlassHeight;
                this.store.order.walls[index].glassConnexThickness = wall.glassConnexThickness;
                this.store.order.walls[index].glassConnexType = wall.glassConnexType;
                this.store.order.walls[index].computedGlassHeight = wall.computedGlassHeight;
                this.store.order.walls[index].bksGlasses = wall.bksGlasses;
                this.store.order.walls[index].holes = wall.holes;
                this.store.order.walls[index].handle = wall.handle;
                this.store.order.walls[index].customDoor = wall.customDoor;
                this.store.order.walls[index].customDoorProfileWidth = wall.customDoorProfileWidth;
                this.store.order.walls[index].doorPosition = wall.doorPosition;

                this.dxfGlassesLink = this.store.Api_url + '/dxf/bks/' + this.store.order.walls[index].id + '?format=download'

                this.validateHeights()
              });

              this.store.order.user = responseData.data.user

              let modifiedDateTemp = new Date(responseData.data.modifiedDate)
              if(Utils.isDSTInEffect(modifiedDateTemp)){
                modifiedDateTemp = Utils.minusOneHour(modifiedDateTemp)
              }else{
                modifiedDateTemp = Utils.minusOneHour(modifiedDateTemp)
                modifiedDateTemp = Utils.minusOneHour(modifiedDateTemp)
              }
              this.store.order.modifiedDate = modifiedDateTemp;

              this.store.pdfProductionLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=screen'
              this.store.pdfProductionPrintLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=print'
              this.store.pdfBksProfileLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=screen'
              this.store.pdfBksProfilePrintLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=print'



              notie.alert({
                type: 'success',
                text: "Úspešne uložené",
                position: "bottom",
                time: 1
              });
              console.log("Úspešne uložené")
            }
          });
    },


  },
  beforeMount() {
    Security.requireToken();
    this.store.hasBeenSent = false;
    this.store.hasBeenInProduction = false;
    this.store.hasBeenReady = false;
    this.store.hasBeenFinished = false;

    this.pdfMaterialLink = "";
    this.pdfGlassLink = "";
    this.store.emailLink = "";
    this.termin = Utils.parseToSlovakDate(this.store.order.installationDate);
    this.recipId = 1;

    if (this.store.order.id === 0) {

      this.store.order.modifiedUser = this.store.user;

      this.store.type = this.store.types.filter(obj => obj.id == this.$route.params.typeId)[0];
      this.store.order.type = this.store.type;
      this.store.order.user = this.store.user;

      console.log('default walls', this.store.order.walls)

    } else {

      console.log('FormInput pdfProductionLink', this.pdfProductionLink)

      this.store.type = this.store.order.type

      this.pdfMaterialLink = this.store.Api_url + '/pdf/m/' + this.store.order.id
      this.pdfMaterialScreenLink = this.store.Api_url + '/pdf/m/' + this.store.order.id + '?format=screen'
      this.pdfMaterialPrintLink = this.store.Api_url + '/pdf/m/' + this.store.order.id + '?format=print'
      this.pdfGlassLink = this.store.Api_url + '/pdf/g/' + this.store.order.id
      this.pdfGlassScreenLink = this.store.Api_url + '/pdf/g/' + this.store.order.id + '?format=print'
      this.pdfProductionLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=screen'
      this.pdfProductionPrintLink = this.store.Api_url + '/pdf/bks-list/' + this.store.order.id + '?format=print'
      this.pdfBksProfileLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=screen'
      this.pdfBksProfilePrintLink = this.store.Api_url + '/pdf/bks-profile/' + this.store.order.id + '?format=print'

      this.store.emailLink = this.store.Api_url + '/email/' + this.store.order.id

      if (this.store.type.code == Models.TypeCode.B_F_H){
        this.store.pdfProductionLink = this.store.Api_url + '/pdf/barrier-list/' + this.store.order.id + '?format=screen'
        this.store.pdfProductionPrintLink = this.store.Api_url + '/pdf/barrier-list/' + this.store.order.id + '?format=print'
        this.store.dxfGlassLink = this.store.Api_url + '/dxf/barrier/' + this.store.order.id + '?format=download'
      }
    }

    if (this.store.type.code == Models.TypeCode.NB_F
        || this.store.type.code == Models.TypeCode.NB_F_L
        || this.store.type.code == Models.TypeCode.NB_F_R
        || this.store.type.code == Models.TypeCode.NB_F_D) {
      this.typeBarrierName = "";
      this.isBarrier = false;
    } else {
      this.typeBarrierName = this.store.barrier;
      this.isBarrier = true;
    }

    if (this.store.type.code == Models.TypeCode.B_F_L || this.store.type.code == Models.TypeCode.NB_F_L || this.store.type.code == Models.TypeCode.B_U) {
      this.isLeft = true;
    }
    if (this.store.type.code == Models.TypeCode.B_F_R || this.store.type.code == Models.TypeCode.NB_F_R || this.store.type.code == Models.TypeCode.B_U) {
      this.isRight = true;
    }

    this.validationOK = true

    Utils.getOrderStates()
    Utils.updateOrderHistory(this.store.order.id);
    Utils.getEmailHistory(this.store.order.id);

    fetch(this.store.Api_url + "/v1/email-recipient",Security.requestOptions("GET"))
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.error) {
            console.log("error:", responseData.message)
            //this.$emit('error', responseData.message);
          } else {
            this.store.emailRecipients = responseData;
            this.store.emailRecipientOptions = [];
            this.store.emailRecipients.forEach((recip: Models.EmailRecipient) => {
              let recipTemp = {
                id: recip.id,
                name: recip.name + " * " + recip.email + " * " + recip.glassType
              }
              this.store.emailRecipientOptions.push(recipTemp)
            })


          }
        });

  },
  mounted() {

    this.validateHeights()

  },
  beforeUnmount() {
    // nastavenie order do defaultných hodnôt
    //this.store.order = {...this.store.order_default}
    //store.order = {...store.order_default}
    //console.log("unmount");
    //const wallsEmpty: WallType[] = [];
    //this.store.order.walls = wallsEmpty;
    //const orderHistoryEmpty: Models.OrderHistory[] = [];
    //this.store.orderHistory = orderHistoryEmpty;

  }

}
</script>

<style scoped>

.p-inputtext {
  background: #b8d6f0;
  text-align: left;
  height: 2.1rem;
}

.p-inputgroup-addon {
  padding: 1rem 0.4rem;
}

.p-myroof {
  padding: 0.99rem 0.4rem;
}

.p-calendar {
  height: 2.1rem;
  padding: 0.1rem 0.2rem;
  background: #b8d6f0;
}

.p-checkbox {
  /*border: 1px solid #757575;*/
  vertical-align: middle;
}

.p-dropdown {
  height: 2.05rem;

  background: #b8d6f0;
}

.p-inputtextarea {
  padding: 0.5rem;
}

.wfloorlab {
  width: 40.1% !important;
}

.wfloorinp {
  width: 42.5% !important;
}


.wfloorlab-2 {
  width: 40.15% !important;
}

.wfloorinp-2 {
  width: 43% !important;
}
.wterminlab {
  width: 40% !important;
}

.wtermincal {
  width: 34% !important;
}

.wtermincal-2 {
  width: 34.2% !important;
}

button {
  border-radius: 6px;
}

.p-button {
  padding: 0.4rem 0.7rem;
}


.p-inputnumber-input {
  height: 2.1rem;

}

.p-inputnumber {
  height: 2.1rem;

}

.bottom-action-row {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 3rem 0 3rem 1rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7973390039609594) 0%, rgba(0, 0, 0, 0) 100%);
}

.p-listbox-list {
  padding-left: 0;
 
}

.email-list {
  top: -167px;
  left: 66.5rem;
}
</style>