<template>
<div class="border-round-lg border-1 border-green-500">

    <div class="surface-border border-round-top-lg justify-content-center bg-green-300">
         <h6 class="text-center uppercase">Profil:  {{this.$props.profile.name}}</h6>
    </div>
    <div class="flex flex-row flex-wrap align-items-center justify-content-center">
      <div class="w-7 p-1">

          <InputGroup class="mt-2">
              <InputGroupAddon class="w-6  font-normal text-base text-700 justify-content-start">Šírka</InputGroupAddon>
              <InputGroupAddon class="w-4  font-normal text-base text-700 ">{{this.$props.profile.width}}</InputGroupAddon>
              <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
          </InputGroup>
          <InputGroup class="mt-2">
              <InputGroupAddon class="w-6  font-normal text-base text-700 justify-content-start">Redukcia skla</InputGroupAddon>
              <InputGroupAddon class="w-4  font-normal text-base text-700 ">{{this.$props.profile.glassReduction}}</InputGroupAddon>
              <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
          </InputGroup>
          <InputGroup class="mt-2">
              <InputGroupAddon class="w-6  font-normal text-base text-700 justify-content-start">Cut redukcia</InputGroupAddon>
              <InputGroupAddon class="w-4  font-normal text-base text-700 ">{{this.$props.profile.cutReduction}}</InputGroupAddon>
              <InputGroupAddon class="w-2  font-normal text-sm">[mm]</InputGroupAddon>
          </InputGroup>  
      </div>
      <div class="w-5 p-1">
        <div class="ml-2 p-2">
          <img :src="this.$props.profile.thumbnail_dim" width="200" height="200" alt="Obrázok">
        </div>
      </div>
    </div>
</div>
</template>

<script lang="ts">


//import {store} from '../store';

//import {ProfileType} from '../models';

export default {
    name: 'CardProfile',
    components:{

    }, 
    props: {
      profile: {
            type: Object,
        }
        //fixGlassKey: Number,
         
    },
    data() {
      return {
        //store,

      }
    },
    methods: {

    }
}
</script>

<style scoped>

.p-inputgroup {
  height: 2rem; 
  /*margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;*/
}
.p-inputgroup-addon {
  padding: 1rem 0.5rem;
 }
 .p-dropdown {
  height: 2.15rem;
  padding: 0.2rem;
  background: #b8d6f0;
 }
 .p-inputtext{
  background: #b8d6f0; 
  text-align: left;
  height: 2.05rem; 
}
.p-inputnumber-input {
  height: 2.1rem;
}


</style>