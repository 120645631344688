import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check mb-3" }
const _hoisted_2 = ["required", "value", "name", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.isChecked) = $event)),
      required: $props.required,
      type: "checkbox",
      value: $props.value,
      name: $props.name,
      id: $props.name
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, $data.isChecked]
    ]),
    _createElementVNode("label", {
      class: "form-check-label",
      for: $props.name
    }, _toDisplayString($props.label), 9, _hoisted_3)
  ]))
}