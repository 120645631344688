import { createRouter, createWebHistory } from 'vue-router'
import BodyLayout from "@/components/BodyLayout.vue";
import LoginComponent from "@/components/LoginComponent.vue";
import FormInput from "@/components/forms/FormInput.vue";
import FormFixInput from "@/components/forms/FormFixInput.vue";
import OrderList from "@/components/OrderList.vue";
import UserList from "@/components/UserList.vue";
import UserEdit from "@/components/UserEdit.vue";
import TypeList from "@/components/TypeList.vue";
import OrderDetail from "@/components/OrderDetail.vue";

const routes = [
    {
        path : '/',
        name: 'TypeList',
        component: TypeList
    },
    {
        path : '/login',
        name: 'Login',
        component: LoginComponent
    },
    {
        path: '/types/:typeId',
        name: 'FormInput',
        component: FormInput
    },
    {
        path: '/fixies',
        name: 'FormFixInput',
        component: FormFixInput
    },
    {
        path: '/orders',
        name: 'OrderList',
        component: OrderList
    },
    {
        path: '/order/:orderId',
        name: 'OrderDetail',
        component: OrderDetail
    },
    {
        path: '/admin/users',
        name: 'UserList',
        component: UserList,
    },
    {
        path: '/admin/users/:userId',
        name: 'UserEdit',
        component: UserEdit,
    }
]

const router = createRouter({
    history : createWebHistory(),
    routes
})

export default router