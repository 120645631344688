<template>
  <HeaderLayout/>
  <div class="surface-50">
    <router-view/>
  </div>
  <FooterLayout/>
</template>

<script lang="ts">
import HeaderLayout from "./components/HeaderLayout.vue"
import FooterLayout from "./components/FooterLayout.vue"
import {store} from "@/components/store";

const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
}

export default {
  name: 'App',
  components: {
    HeaderLayout,
    FooterLayout,
  },
  data() {
    return {
      store
    }
  },
  beforeMount() {
    // check for a cookie
    let data = getCookie("_bks_data");

    if (data !== "") {
      let cookieData = JSON.parse(data);

      store.token = cookieData.token.UserToken;

      store.user = {
        id: cookieData.user.id,
        firstName: cookieData.user.firstName,
        lastName: cookieData.user.lastName,
        email: cookieData.user.email,
        admin: cookieData.user.admin
      };

    }
  }
}
</script>

<style>

</style>
